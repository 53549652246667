import api from "state/api";
import { getConfigWithBearerToken } from "state/api";

export const getAutomationsApi = async () => {
  try {
    const response = await api.get(`/automations/config`, getConfigWithBearerToken());
    return response.data;
  } catch (error) {
    console.error("Error getAutomationsApi:", error.message);
  }
};

export const updateAutomationsApi = async (scrapers) => {
  try {
    const response = await api.put(
      "/automations/config",
      { scrapers },
      getConfigWithBearerToken()
    );
    return response.data;
  } catch (error) {
    console.error("Error updateAutomationsApi:", error.message);
  }
};