import React, { useState, useEffect } from "react";

import {
  Box,
  TextField,
  Input,
  Checkbox,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { Visibility, VisibilityOff } from "@mui/icons-material";

import { Formik } from "formik";
import * as yup from "yup";

import { useDispatch, useSelector } from "react-redux";
import { getSuppliers } from "features/suppliers/suppliersSlice";

const StoreForm = ({
  type,
  formValues,
  setFormValues,
  suppliers,
  setSuppliers,
  setConfirmDialogOpen,
  storeStatus,
  confirmText,
  passwordPlaceholder
}) => {
  const dispatch = useDispatch();
  const { supplierIdPairs } = useSelector((state) => state.suppliers);

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    dispatch(getSuppliers({ type: 'id-pairs' }));
  }, []);

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const checkoutSchema = yup.object().shape({
    storeName: yup.string().required('required'),
    storeEmail: yup.string().email('invalid email').required('required'),
    storePassword: type === 'edit' ? yup.string().min(8, 'Password must be at least 8 characters') : yup.string().required('required').min(8, 'Password must be at least 8 characters'),
    storeRedirectUriRuName: yup.string(),
  });

  const handleOnSubmit = (values) => {
    setFormValues({
      ...values,
      supplierIds: suppliers,
    });

    setConfirmDialogOpen(true)
  }

  return (
    <Formik
      onSubmit={handleOnSubmit}
      initialValues={formValues}
      validationSchema={checkoutSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          >
            <TextField
              color="secondary"
              fullWidth
              variant="filled"
              type="text"
              label="Store Name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.storeName}
              name="storeName"
              error={!!touched.storeName && !!errors.storeName}
              helperText={touched.storeName && errors.storeName}
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              color="secondary"
              fullWidth
              variant="filled"
              type="text"
              label="Store Email ( connected with ebay store )"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.storeEmail}
              name="storeEmail"
              error={!!touched.storeEmail && !!errors.storeEmail}
              helperText={touched.storeEmail && errors.storeEmail}
              sx={{ gridColumn: "span 2" }}
            />
            <FormControl fullWidth sx={{ gridColumn: 'span 2' }}>
              <InputLabel
                color="secondary"
                id="select-supplier-label"
              >Supplier</InputLabel>
              <Select
                color="secondary"
                labelId="select-supplier-label"
                id="select-supplier"
                multiple
                value={suppliers}
                renderValue={(selected) => {
                  return selected.map((id, index) => {
                    // Find the corresponding supplierNonCamelCase for each selected ID
                    const supplier = supplierIdPairs.find((supplier) => supplier._id === id);
                    return supplier ? supplier.supplierNonCamelCase : '';
                  }).join(', ');
                }}
                label="Supplier"
                onBlur={handleBlur}
                onChange={(e) => setSuppliers(e.target.value)}
                error={!!touched.supplier && !!errors.supplier}
                input={<Input />}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 48 * 4.5 + 8,
                      width: 250,
                    },
                  },
                }}
              >
                {supplierIdPairs &&
                  supplierIdPairs.map((supplier) => (
                    <MenuItem key={supplier._id} value={supplier._id}>
                      <Checkbox
                        checked={suppliers.indexOf(supplier._id) > -1}
                        color="secondary"
                      />
                      {supplier.supplierNonCamelCase}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <TextField
              color="secondary"
              fullWidth
              variant="filled"
              type={showPassword ? "text" : "password"}
              label={passwordPlaceholder}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.storePassword}
              name="storePassword"
              error={!!touched.storePassword && !!errors.storePassword}
              helperText={touched.storePassword && errors.storePassword}
              sx={{ gridColumn: "span 2" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Redirect URI RuName ( generated from developer account )"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.storeRedirectUriRuName}
              name="storeRedirectUriRuName"
              sx={{ gridColumn: "span 4" }}
              color="secondary"
            />
          </Box>
          <Box display="flex" justifyContent="end" mt="20px">
            <LoadingButton
              type="submit"
              color="secondary"
              variant="contained"
              sx={{
                width: "20%",
                padding: "10px"
              }}
              loading={storeStatus === "pending"}
            >
              {confirmText}
            </LoadingButton>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default StoreForm;