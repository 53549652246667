import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import {
  Box,
  TextField,
  useTheme
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import Header from "components/Header";
import SubHeader from "components/SubHeader";
import DialogBox from "components/DialogBox";

import { useDispatch, useSelector } from "react-redux";
import {
  getDevConfig,
  updateDevConfig
} from "features/marketplaces/marketplacesSlice";

const Dev = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    updateStatus,
    devConfig
  } = useSelector((state) => state.marketplaces);

  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");

  const [saveDialogOpen, setSaveDialogOpen] = useState(false);

  useEffect(() => {
    dispatch(getDevConfig());
  }, []);

  useEffect(() => {
    setClientId(devConfig.clientId);
    setClientSecret(devConfig.clientSecret);
  }, [devConfig]);

  const handleUpdate = () => {
    dispatch(updateDevConfig({ clientId, clientSecret }));

    setSaveDialogOpen(false);
  };

  return (
    <Box m="1.5rem 2.5rem" pb="2rem">
      <Header title="eBay Dev" subtitle="Edit And Manage Your eBay Dev" />
      <Box
        mt="3rem"
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "75%",
          gap: "2rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <SubHeader title="Credentials" />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "60%",
              borderRadius: "10px",
              bgcolor: theme.palette.background.alt,
              padding: "2rem",
              gap: "2rem"
            }}
          >
            <TextField
              id="outlined-textarea"
              label="Client Id"
              placeholder="Client Id..."
              value={clientId}
              onChange={(e) => setClientId(e.target.value)}
              fullWidth
              color="secondary"
            />
            <TextField
              id="outlined-textarea"
              label="Client Secret"
              placeholder="Client Secret..."
              value={clientSecret}
              onChange={(e) => setClientSecret(e.target.value)}
              fullWidth
              color="secondary"
            />
          </Box>
        </Box>
        <LoadingButton
          color="secondary"
          variant="contained"
          sx={{
            width: "20%",
            padding: "10px",
            alignSelf: "flex-end"
          }}
          onClick={() => setSaveDialogOpen(true)}
          loading={updateStatus === "pending"}
        >
          Save
        </LoadingButton>
      </Box>
      <DialogBox
        open={saveDialogOpen}
        onClose={() => setSaveDialogOpen(false)}
        onConfirm={handleUpdate}
        titleText="Save Confirmation"
        messageText="Are you sure you want to save this change?"
        confirmText="Save"
      />
    </Box>
  );
}

export default Dev;
