import { useMemo, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import {
  CssBaseline,
  ThemeProvider,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";

import { useSelector } from "react-redux";
import { themeSettings } from "theme";

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

import LoadingBar from 'react-top-loading-bar'

import socket from "app/socket";

import ProtectedRoute from "auth/ProtectedRoute";
import SignIn from "scenes/signIn";

import Layout from "scenes/layout";

import Dashboard from "scenes/dashboard";
import Account from "scenes/account";
import Users from "scenes/users";
import CreateUser from "scenes/createUser";
import Listings from "scenes/listings";
import ListingEdit from "scenes/listingEdit";
import Suppliers from "scenes/suppliers";
import SupplierEdit from "scenes/supplierEdit";
import Stores from "scenes/stores";
import EditStore from "scenes/editStore";
import StoreEdit from "scenes/storeEdit";
import CreateStore from "scenes/createStore";
import SourcingSetup from "scenes/sourcingSetup";
import Dev from "scenes/dev";
import ScraperConfig from "scenes/scraperConfig";
import ScrapedItems from "scenes/scrapedItems";
import ScrapedItemEdit from "scenes/scrapedItemEdit";
import CreateListings from "scenes/createListings";
import Automations from "scenes/automations";

function App() {
  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  const [progress, setProgress] = useState(0)

  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <CssBaseline />
            <LoadingBar
              color="#52259A"
              progress={progress}
              onLoaderFinished={() => setProgress(0)}
            />
            <Routes>
              <Route path="/sign-in" element={<SignIn />} />
              <Route element={<ProtectedRoute setProgress={setProgress} element={<Layout />} />}>
                <Route index element={<Navigate to="/dashboard" replace />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/listings" element={<Listings setProgress={setProgress} />} />
                <Route path="/listings/:listingId" element={<ListingEdit />} />
                <Route path="/users" element={<Users />} />
                <Route path="/create user" element={<CreateUser />} />
                <Route path="/account" element={<Account />} />
                <Route path="/stores" element={<Stores />} />
                <Route path="/stores/:storeId/sourcing setup" element={<SourcingSetup />} />
                <Route path="/stores/:storeId/edit" element={<EditStore />} />
                <Route path="/stores/:storeId/create listings" element={<CreateListings />} />
                <Route path="/stores/:storeId" element={<StoreEdit />} />
                <Route path="/stores/create" element={<CreateStore />} />
                <Route path="/suppliers" element={<Suppliers />} />
                <Route path="/suppliers/:supplierId" element={<SupplierEdit />} />
                <Route path="/scrapers" element={<ScraperConfig />} />
                <Route path="/dev" element={<Dev />} />
                <Route path="/scraped items" element={<ScrapedItems setProgress={setProgress} />} />
                <Route path="/scraped items/:scrapedItemId" element={<ScrapedItemEdit />} />
                <Route path="/automations" element={<Automations />} />
              </Route>
            </Routes>
          </LocalizationProvider>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
