import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getListingsApi,
  getListingsXlsxApi,
  getListingApi,
  updateListingApi,
  updateListingsFromXlsxApi,
  deleteListingApi,
  syncListingsApi
} from "./listingsApi";
import { notifyMsg } from "state/api";

export const getListings = createAsyncThunk("listings/getListings", async ({ page, pageSize, search, filters }) => {
  const response = await getListingsApi(page, pageSize, search, filters);
  return response;
});

export const getListingsXlsx = createAsyncThunk("listings/getListingsXlsx", async ({ search, filters }) => {
  const response = await getListingsXlsxApi(search, filters);
  return response;
});

export const getListing = createAsyncThunk("listings/getListing", async (listingId) => {
  const response = await getListingApi(listingId);
  return response;
});

export const updateListing = createAsyncThunk("listings/updateListing", async ({ listingId, updatedListing }) => {
  const response = await updateListingApi(listingId, updatedListing);
  notifyMsg(response);
  return response;
});

export const updateListingsFromXlsx = createAsyncThunk("listings/updateListingsFromXlsx", async (file) => {
  const response = await updateListingsFromXlsxApi(file);
  notifyMsg(response);
  return response;
});

export const deleteListing = createAsyncThunk("listings/deleteListing", async ({ type, data }) => {
  const response = await deleteListingApi(type, data);
  notifyMsg(response);
  return response;
});

export const syncListings = createAsyncThunk("listings/syncListings", async () => {
  const response = await syncListingsApi();
  notifyMsg(response);
  return response;
});

const initialState = {
  listingsLoading: false,
  listingsXlsxLoading: false,
  updateStatus: "",
  deleteStatus: "",
  updateFromXlsxStatus: "",
  syncStatus: "",
  listings: [],
  totalResults: 0,
  totalPages: 0,
  filters: {
    supplier: '',
    store: '',
    price: {
      minimum: '',
      maximum: ''
    },
    quantity: {
      minimum: '',
      maximum: ''
    },
    soldQuantity: {
      minimum: '',
      maximum: ''
    },
    nonSynced: false,
    synced: false,
    fixedPrice: false,
    errorType: ''
  },
  listingInfo: {},
};

const listingsSlice = createSlice({
  name: "listingsSlice",
  initialState,
  reducers: {
    clearStatus: (state) => {
      state.createUserStatus = "";
    },
    updateFilter: (state, { payload }) => {
      const { type, value } = payload;
      state.filters[type] = value;
    },
    updateRangeFilter: (state, { payload }) => {
      const { type, value } = payload;
      state.filters[type].minimum = value.split('-')[0];
      state.filters[type].maximum = value.split('-')[1];
    },
    clearFilters: (state) => {
      state.filters = {
        supplier: '',
        store: '',
        price: {
          minimum: '',
          maximum: ''
        },
        quantity: {
          minimum: '',
          maximum: ''
        },
        soldQuantity: {
          minimum: '',
          maximum: ''
        },
        nonSynced: false,
        synced: false,
        fixedPrice: false,
        errorType: ''
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListings.pending, (state) => {
        state.listingsLoading = true;
      })
      .addCase(getListings.fulfilled, (state, { payload }) => {
        if (payload) {
          state.listingsLoading = false;
          state.listings = payload.data.listings;
          state.totalResults = payload.data.totalResults;
          state.totalPages = payload.data.totalPages;
        }
      })
      .addCase(getListings.rejected, (state) => {
        state.listingsLoading = true;
      })
      // getListingsXlsx
      .addCase(getListingsXlsx.pending, (state) => {
        state.listingsXlsxLoading = true;
      })
      .addCase(getListingsXlsx.fulfilled, (state) => {
        state.listingsXlsxLoading = false;
      })
      .addCase(getListingsXlsx.rejected, (state) => {
        state.listingsXlsxLoading = false;
      })
      // getListing
      .addCase(getListing.fulfilled, (state, { payload }) => {
        state.listingInfo = payload.data.listingItem;
      })
      // updateListing
      .addCase(updateListing.pending, (state) => {
        state.updateStatus = "pending";
      })
      .addCase(updateListing.fulfilled, (state, { payload }) => {
        state.updateStatus = "fulfilled";
      })
      .addCase(updateListing.rejected, (state) => {
        state.updateStatus = "rejected";
      })
      // updateListingsFromXlsx
      .addCase(updateListingsFromXlsx.pending, (state) => {
        state.updateFromXlsxStatus = "pending";
      })
      .addCase(updateListingsFromXlsx.fulfilled, (state, { payload }) => {
        state.updateFromXlsxStatus = "fulfilled";
      })
      .addCase(updateListingsFromXlsx.rejected, (state) => {
        state.updateFromXlsxStatus = "rejected";
      })
      // syncListings
      .addCase(syncListings.pending, (state) => {
        state.syncStatus = "pending";
      })
      .addCase(syncListings.fulfilled, (state, { payload }) => {
        state.syncStatus = "fulfilled";
      })
      .addCase(syncListings.rejected, (state) => {
        state.syncStatus = "rejected";
      })
      // deleteListing
      .addCase(deleteListing.pending, (state) => {
        state.deleteStatus = "pending";
      })
      .addCase(deleteListing.fulfilled, (state, { payload }) => {
        state.deleteStatus = "fulfilled";
      })
      .addCase(deleteListing.rejected, (state) => {
        state.deleteStatus = "rejected";
      })
  },
});

export const { updateFilter, updateRangeFilter, clearFilters } = listingsSlice.actions;
export default listingsSlice.reducer;