import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  updateDevConfigApi,
  getDevConfigApi,
  updateAuthorizatioCodeApi,
  fetchEbayCategoriesApi,
  getCategoryApi,
  getItemSpecificsApi,
  updateItemSpecificsApi,
  formatUploadApi,
  addListingsApi
} from "./marketplacesApi";
import { notifyMsg } from "state/api";

export const updateDevConfig = createAsyncThunk("marketplaces/updateDevConfig", async (credentials) => {
  const response = await updateDevConfigApi(credentials);
  notifyMsg(response);
  return response;
});

export const getDevConfig = createAsyncThunk("marketplaces/getDevConfig", async () => {
  const response = await getDevConfigApi();
  return response;
});

export const fetchEbayCategories = createAsyncThunk("marketplaces/fetchEbayCategories", async (storeId) => {
  const response = await fetchEbayCategoriesApi(storeId);
  return response;
});

export const formatUpload = createAsyncThunk("marketplaces/formatUpload", async ({ storeId, file }) => {
  const response = await formatUploadApi(storeId, file);
  return response;
});

export const getCategory = createAsyncThunk("marketplaces/getCategory", async (categoryIds) => {
  const response = await getCategoryApi(categoryIds);
  return response;
});

export const updateAuthorizatioCode = createAsyncThunk("marketplaces/updateAuthorizatioCode", async ({ storeId, code }) => {
  const response = await updateAuthorizatioCodeApi(storeId, code);
  notifyMsg(response);
  return response;
});

export const getItemSpecifics = createAsyncThunk("marketplaces/getItemSpecifics", async () => {
  const response = await getItemSpecificsApi();
  return response;
});

export const updateItemSpecifics = createAsyncThunk("marketplaces/updateItemSpecifics", async (itemSpecifics) => {
  const response = await updateItemSpecificsApi(itemSpecifics);
  notifyMsg(response);
  return response;
});


export const addListings = createAsyncThunk("marketplaces/addListings", async ({ storeId, listings, itemSpecifics }) => {
  const response = await addListingsApi(storeId, listings, itemSpecifics);
  notifyMsg(response);
  return response;
});

const initialState = {
  formatUploadStatus: "",
  updateStatus: "",
  categories: [],
  categoryString: "",
  subcategories: [],
  itemSpecificsUpdate: "",
  itemSpecifics: [],
  lastSubcategory: false,
  formatedUpload: [],
  devConfig: {},
  addListingsStatus: ""
};

const marketplacesSlice = createSlice({
  name: "marketplacesSlice",
  initialState,
  reducers: {
    resetCategory: (state) => {
      state.categoryString = "";
      state.subcategories = [];
    }
  },
  extraReducers: (builder) => {
    builder
      // updateDevConfig
      .addCase(updateDevConfig.pending, (state) => {
        state.updateStatus = "pending";
      })
      .addCase(updateDevConfig.fulfilled, (state, { payload }) => {
        state.updateStatus = "fulfilled";
      })
      .addCase(updateDevConfig.rejected, (state) => {
        state.updateStatus = "rejected";
      })
      // getDevConfig
      .addCase(getDevConfig.fulfilled, (state, { payload }) => {
        state.devConfig = payload.data ? payload.data : {};
      })
      // getCategory
      .addCase(getCategory.fulfilled, (state, { payload }) => {
        if (Array.isArray(payload.data)) {
          state.categories = payload.data;
        } else {
          state.categoryString = payload.data.categoryString;
          state.subcategories = payload.data.subcategories;
          if (state.subcategories.length < 1) {
            state.lastSubcategory = true;
          } else {
            state.lastSubcategory = false;
          }
        }
      })
      // getItemSpecifics
      .addCase(getItemSpecifics.fulfilled, (state, { payload }) => {
        state.itemSpecifics = payload.data;
      })
      // updateItemSpecifics
      .addCase(updateItemSpecifics.pending, (state) => {
        state.itemSpecificsUpdate = "pending";
      })
      .addCase(updateItemSpecifics.fulfilled, (state) => {
        state.itemSpecificsUpdate = "fulfilled";
      })
      .addCase(updateItemSpecifics.rejected, (state) => {
        state.itemSpecificsUpdate = "rejected";
      })
      // addListings
      .addCase(addListings.pending, (state) => {
        state.addListingsStatus = "pending";
      })
      .addCase(addListings.fulfilled, (state) => {
        state.addListingsStatus = "fulfilled";
      })
      .addCase(addListings.rejected, (state) => {
        state.addListingsStatus = "rejected";
      })
      // formatUpload
      .addCase(formatUpload.pending, (state) => {
        state.formatUploadStatus = "pending";
      })
      .addCase(formatUpload.fulfilled, (state, { payload }) => {
        state.formatedUpload = payload.data;
        state.formatUploadStatus = "fulfilled";
      })
      .addCase(formatUpload.rejected, (state) => {
        state.formatUploadStatus = "rejected";
      });
  },
});

export const { resetCategory } = marketplacesSlice.actions;
export default marketplacesSlice.reducer;