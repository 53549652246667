import { configureStore } from "@reduxjs/toolkit";
import globalReducer from "state";
import { api } from "state/api copy";

import userSlice from "features/user/userSlice";
import staffSlice from "features/staff/staffSlice";
import suppliersSlice from "features/suppliers/suppliersSlice";
import storesSlice from "features/stores/storesSlice";
import listingsSlice from "features/listings/listingsSlice";
import marketplacesSlice from "features/marketplaces/marketplacesSlice";
import automationsSlice from "features/automations/automationsSlice";

const store = configureStore({
  reducer: {
    global: globalReducer,
    [api.reducerPath]: api.reducer,
    user: userSlice,
    staff: staffSlice,
    suppliers: suppliersSlice,
    stores: storesSlice,
    listings: listingsSlice,
    marketplaces: marketplacesSlice,
    automations: automationsSlice,
  },
  middleware: (getDefault) => getDefault().concat(api.middleware),
});

export default store;