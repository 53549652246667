import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAutomationsApi, updateAutomationsApi } from "./automationsApi";
import { notifyMsg } from "state/api";

export const updateAutomations = createAsyncThunk("ebay/updateAutomations", async ({ scrapers }) => {
  const response = await updateAutomationsApi(scrapers);
  notifyMsg(response);
  return response;
});

export const getAutomations = createAsyncThunk("ebay/getAutomations", async () => {
  const response = await getAutomationsApi();
  return response;
});

const initialState = {
  updateStatus: "",
  automations: {}
};

const automationsSlice = createSlice({
  name: "automationsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // updateAutomations
      .addCase(updateAutomations.pending, (state) => {
        state.updateStatus = "pending";
      })
      .addCase(updateAutomations.fulfilled, (state, { payload }) => {
        state.updateStatus = "fulfilled";
      })
      .addCase(updateAutomations.rejected, (state) => {
        state.updateStatus = "rejected";
      })
      // getAutomations
      .addCase(getAutomations.fulfilled, (state, { payload }) => {
        state.automations = payload.data ? payload.data : {};
      });
  },
});

export default automationsSlice.reducer;
