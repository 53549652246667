import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  Box,
  Button,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import Header from "components/Header";
import SubHeader from "components/SubHeader";
import DialogBox from "components/DialogBox";

import SupplierDataUpload from "components/SupplierDataUpload";

import { useDispatch, useSelector } from "react-redux";
import {
  getSupplierInfo,
  fixedShippingFormat,
  fixedShippingUpload,
  updateShippingWeights,
  updateSupplierInfo
} from "features/suppliers/suppliersSlice";

const SupplierEdit = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    supplierInfo,
    updateStatus,
    shippingFormatStatus,
  } = useSelector((state) => state.suppliers);

  const { supplierId } = useParams();

  const [shippingFile, setShippingFile] = useState(null);
  const [weightShippings, setWeightShippings] = useState([]);
  const [skuPrefix, setSkuPrefix] = useState("");

  const [saveDialogOpen, setSaveDialogOpen] = useState(false);

  useEffect(() => {
    setWeightShippings(supplierInfo.weightShippingCost);
    setSkuPrefix(supplierInfo.skuPrefix);
  }, [supplierInfo]);

  useEffect(() => {
    dispatch(getSupplierInfo(supplierId));
  }, []);

  const handleSave = () => {
    if (shippingFile) {
      dispatch(fixedShippingUpload({ supplierId, file: shippingFile }));
      setShippingFile(null);
    }

    if (JSON.stringify(supplierInfo.weightShippingCost) !== JSON.stringify(weightShippings)) {
      dispatch(updateShippingWeights({ supplierId, shippingWeights: weightShippings }));
    }

    if (supplierInfo.skuPrefix !== skuPrefix) {
      dispatch(updateSupplierInfo({ supplierId, skuPrefix }));
    }

    setSaveDialogOpen(false);

    dispatch(getSupplierInfo(supplierId));
  };

  const handleFormatDownload = (type) => {
    if (type === "Fixed Shipping") {
      dispatch(fixedShippingFormat());;
    }
  };

  // Weight Shipping
  const handleAddRow = () => {
    setWeightShippings([...weightShippings, { minWeight: "", maxWeight: "", cost: "" }]);
  };

  const handleRemoveRow = (index) => {
    const updatedRows = [...weightShippings];
    updatedRows.splice(index, 1);
    setWeightShippings(updatedRows);
  };

  const handleRowChange = (index, field, value) => {
    const updatedRows = weightShippings.map((row, i) =>
      i === index ? { ...row, [field]: value } : row
    );
    setWeightShippings(updatedRows);
  };

  return (
    <Box m="1.5rem 2.5rem" pb="2rem">
      <Header title={supplierInfo.supplierNonCamelCase || "Supplier Edit"} subtitle="Edit And Manage Your Supplier" />
      <Box
        mt="3rem"
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "75%",
          gap: "2rem",
        }}
      >
        <SupplierDataUpload
          type="Fixed Shipping"
          title="Fixed Shipping"
          lastUploadDate={supplierInfo.lastShippingUpload}
          status={shippingFormatStatus}
          selectedFile={shippingFile}
          setSelectedFile={setShippingFile}
          handleFormatDownload={handleFormatDownload}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <SubHeader title="Supplier Settings" />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "60%",
              borderRadius: "10px",
              bgcolor: theme.palette.background.alt,
              padding: "2rem",
              gap: "2rem"
            }}
          >
            <Box>
              <Typography fontWeight="bold" variant="h5" p="3px" mb="20px">Supplier Sku Prefix</Typography>
              <TextField
                id="outlined-textarea"
                label="Sku Prefix"
                placeholder="Sku Prefix..."
                value={skuPrefix}
                onChange={(e) => setSkuPrefix(e.target.value)}
                color="secondary"
                sx={{
                  width: "50%"
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <SubHeader title="Shipping Weights" />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "60%",
              borderRadius: "10px",
              bgcolor: theme.palette.background.alt,
              padding: "2rem",
              gap: "2rem"
            }}
          >
            {weightShippings.map((row, index) => (
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    variant="outlined"
                    label="Min. Weight"
                    value={row.minWeight}
                    onChange={(e) => handleRowChange(index, "minWeight", e.target.value)}
                    color="secondary"
                  />
                  <TextField
                    variant="outlined"
                    label="Max. Weight"
                    value={row.maxWeight}
                    onChange={(e) => handleRowChange(index, "maxWeight", e.target.value)}
                    color="secondary"
                  />
                  <TextField
                    variant="outlined"
                    label="Cost"
                    value={row.cost}
                    onChange={(e) => handleRowChange(index, "cost", e.target.value)}
                    color="secondary"
                  />
                </Box>
                <Box>
                  {weightShippings.length > 0 && (
                    <Button
                      color="secondary"
                      variant="contained"
                      sx={{
                        padding: "10px",
                        backgroundColor: "transparent",
                        boxShadow: "none",
                        transition: "background-color 0.3s",
                        color: theme.palette.secondary.main,
                        "&:hover": {
                          backgroundColor: theme.palette.secondary.main,
                          color: "#000"
                        }
                      }}
                      onClick={() => handleRemoveRow(index)}
                    >Remove</Button>
                  )}
                </Box>
              </Box>
            ))}
            <Button
              color="secondary"
              variant="contained"
              sx={{
                width: "30%",
                padding: "10px"
              }}
              onClick={handleAddRow}
            >Add</Button>
          </Box>
        </Box>
        <LoadingButton
          color="secondary"
          variant="contained"
          sx={{
            width: "20%",
            padding: "10px",
            alignSelf: "flex-end"
          }}
          onClick={() => setSaveDialogOpen(true)}
          loading={updateStatus === "pending"}
        >Save</LoadingButton>
      </Box>
      <DialogBox
        open={saveDialogOpen}
        onClose={() => setSaveDialogOpen(false)}
        onConfirm={handleSave}
        titleText="Save Confirmation"
        messageText="Are you sure you want to save this change?"
        confirmText="Save"
      />
    </Box>
  );
}

export default SupplierEdit;
