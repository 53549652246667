import React, { useState } from "react";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme
} from "@mui/material";
import {
  SettingsOutlined,
  ChevronLeft,
  ChevronRightOutlined,
  HomeOutlined,
  ShoppingCartOutlined,
  LocalMallOutlined,
  FactoryOutlined,
  PersonOutlined,
  PeopleOutlined,
  Inventory2Outlined,
  AutorenewOutlined,
  TuneOutlined,
  PrecisionManufacturingOutlined,
  BrowserUpdatedOutlined
} from "@mui/icons-material";

import { useNavigate, Link } from "react-router-dom";
import FlexBetween from "./FlexBetween";
import Logo from "assets/logo.png";

import { useSelector } from "react-redux";

const navItems = [
  {
    text: "Dashboard",
    icon: <HomeOutlined />,
  },
  {
    text: "Management",
    icon: null,
  },
  {
    text: "Listings",
    icon: <LocalMallOutlined />,
  },
  {
    text: "Scraped Items",
    icon: <BrowserUpdatedOutlined />,
  },
  {
    text: "Stores",
    icon: <ShoppingCartOutlined />,
  },
  {
    text: "Suppliers",
    icon: <FactoryOutlined />,
  },
  {
    text: "Scrapers",
    icon: <PrecisionManufacturingOutlined />,
  },
  {
    text: "Automations",
    icon: <AutorenewOutlined />,
  },
  {
    text: "Dev",
    icon: <TuneOutlined />,
  },
  {
    text: "Admin",
    icon: null,
  },
  {
    text: "Create User",
    icon: <PersonOutlined />,
  },
  {
    text: "Users",
    icon: <PeopleOutlined />,
  }
];

const Sidebar = ({
  user,
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  isNonMobile,
}) => {
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();
  const { profileInfo } = useSelector((state) => state.user);

  return (
    <Box component="nav">
      {isSidebarOpen && (
        <Drawer
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              color: theme.palette.secondary[200],
              backgroundColor: theme.palette.background.default,
              boxSixing: "border-box",
              borderWidth: isNonMobile ? 0 : "2px",
              width: drawerWidth,
              borderRight: `1px dashed ${theme.palette.secondary[200]}`
            },
          }}
        >
          <Box width="100%">
            <Box m="1.5rem 2rem 2rem 2rem">
              <FlexBetween color={theme.palette.secondary.main}>
                <Box display="flex" alignItems="center" gap="0.5rem">
                  <img src={Logo} alt="Logo" height="40px" />
                  <Typography variant="h4" fontWeight="bold" sx={{ color: theme.palette.grey[400] }}>
                    SECRET WHOLESALE
                  </Typography>
                </Box>
                {!isNonMobile && (
                  <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                    <ChevronLeft />
                  </IconButton>
                )}
              </FlexBetween>
            </Box>
            <List>
              {navItems.map(({ text, icon, path }) => {
                if (!icon) {
                  return (
                    <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem", color: theme.palette.grey[400] }}>
                      {text}
                    </Typography>
                  );
                }
                const lcText = text.toLowerCase();

                return (
                  <ListItem key={text} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        if (path) {
                          navigate(path);
                          setActive(path.substring(1));
                        } else {
                          navigate(`/${lcText}`);
                          setActive(lcText);
                        }
                      }}
                      sx={{
                        backgroundColor:
                          active === lcText
                            // ? theme.palette.secondary[500]
                            ? `rgba(${parseInt(theme.palette.secondary[500].slice(1, 3), 16)}, ${parseInt(theme.palette.secondary[500].slice(3, 5), 16)}, ${parseInt(theme.palette.secondary[500].slice(5, 7), 16)}, 0.2)`
                            : "transparent",
                        color:
                          active === lcText
                            ? theme.palette.secondary[500]
                            : theme.palette.grey[500],
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          ml: "2rem",
                          color:
                            active === lcText
                              ? theme.palette.secondary[500]
                              : theme.palette.grey[500],
                        }}
                      >
                        {icon}
                      </ListItemIcon>
                      <ListItemText primary={text} />
                      {active === lcText && (
                        <ChevronRightOutlined sx={{ ml: "auto" }} />
                      )}
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>
          <Box position="absolute" bottom="2rem" width="100%">
            <Divider variant="middle" />
            <FlexBetween textTransform="none" m="1.5rem 2rem 0 3rem" width="40%">
              <Box
                component="img"
                alt="profile"
                src={
                  profileInfo.profileImage ?
                    `${process.env.REACT_APP_BASE_PREFIX}/profileImages/${profileInfo.profileImage}` :
                    (theme.palette.mode === "dark" ?
                      process.env.REACT_APP_DEFAULT_PFP_LIGHT :
                      process.env.REACT_APP_DEFAULT_PFP_DARK)
                }
                height="40px"
                width="40px"
                borderRadius="50%"
                sx={{ objectFit: "cover" }}
              />
              <Box textAlign="left">
              </Box>
              <Link to="/account" style={{ textDecoration: "none" }}>
                <SettingsOutlined
                  sx={{
                    color: theme.palette.secondary[300],
                    fontSize: "25px",
                  }}
                />
              </Link>
            </FlexBetween>
          </Box>
        </Drawer>
      )}
    </Box>
  );
};

export default Sidebar;
