import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  signInApi,
  signOutApi,
  getProfileInfoApi,
  updateProfileInfoApi,
  updateProfileImageApi,
  passwordResetEmailApi,
  verifyPasswordResetApi
} from "./userApi";
import { notifyMsg } from "state/api";

export const signIn = createAsyncThunk("auth/signIn", async (credentials) => {
  const response = await signInApi(credentials);
  return response;
});

export const signOut = createAsyncThunk("auth/signOut", async () => {
  const response = await signOutApi();
  return response;
});

export const getProfileInfo = createAsyncThunk("profile/getProfileInfo", async () => {
  const response = await getProfileInfoApi();
  return response;
});

export const updateProfileInfo = createAsyncThunk("profile/updateProfileInfo", async (updatedProfileData) => {
  const response = await updateProfileInfoApi(updatedProfileData);
  notifyMsg(response);
  return response;
});

export const updateProfileImage = createAsyncThunk("profile/updateProfileImage", async (imageFile) => {
  const response = await updateProfileImageApi(imageFile);
  notifyMsg(response);
  return response;
});

export const passwordResetEmail = createAsyncThunk("security/passwordResetEmail", async (userEmail) => {
  const response = await passwordResetEmailApi(userEmail);
  return response;
});

export const verifyPasswordReset = createAsyncThunk("security/verifyPasswordReset", async ({ userEmail, resetCode }) => {
  const response = await verifyPasswordResetApi(userEmail, resetCode);
  return response;
});

const initialState = {
  user: null,
  signInStatus: "",
  updateStatus: "",
  profileInfo: {
    firstName: "",
    lastName: "",
    email: "",
    profileImage: ""
  },
  resetEmailLoading: false,
  resetEmailStatus: "idle"
};

const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    clearStatus: (state) => {
      state.signInStatus = "";
    },
    updateResetEmailStatus: (state, { payload }) => {
      state.resetEmailStatus = payload;
    }
  },
  extraReducers: (builder) => {
    builder
      // signIn
      .addCase(signIn.pending, (state) => {
        state.signInStatus = "Signing In...";
      })
      .addCase(signIn.fulfilled, (state, { payload }) => {
        if (payload) {
          state.user = payload.data.userId;
          state.signInStatus = "Successful";
          localStorage.setItem('token', payload.data.token);
        } else {
          state.signInStatus = "Failed";
        }
      })
      .addCase(signIn.rejected, (state) => {
        state.signInStatus = "Failed";
      })
      // signOut
      .addCase(signOut.fulfilled, (state, { payload }) => {
        if (payload) {;
          localStorage.removeItem("token");
        }
      })
      // getProfileInfo
      .addCase(getProfileInfo.fulfilled, (state, { payload }) => {
        if (payload && payload.success) {
          state.profileInfo = payload.data.profileData;
        }
      })
      // updateProfileInfo
      .addCase(updateProfileInfo.pending, (state) => {
        state.updateStatus = "pending";
      })
      .addCase(updateProfileInfo.fulfilled, (state, { payload }) => {
        state.updateStatus = "fulfilled";
      })
      .addCase(updateProfileInfo.rejected, (state) => {
        state.updateStatus = "rejected";
      })
      // updateProfileImage
      .addCase(updateProfileImage.pending, (state) => {
        state.pfpUpdateLoading = true;
      })
      .addCase(updateProfileImage.fulfilled, (state) => {
        state.pfpUpdateLoading = false;
      })
      .addCase(updateProfileImage.rejected, (state) => {
        state.pfpUpdateLoading = false;
      })
      // passwordResetEmail
      .addCase(passwordResetEmail.pending, (state) => {
        state.resetEmailLoading = true;
      })
      .addCase(passwordResetEmail.fulfilled, (state) => {
        state.resetEmailStatus = 'fulfilled';
      })
      .addCase(passwordResetEmail.rejected, (state) => {
        state.resetEmailStatus = 'rejected';
      });
  }
});

export const { clearStatus, updateResetEmailStatus } = userSlice.actions;
export default userSlice.reducer;
