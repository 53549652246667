import api from "state/api";
import { getConfigWithBearerToken } from "state/api";

export const signInApi = async (credentials) => {
  try {
    const response = await api.post("user/auth/sign-in", credentials);
    return response.data;
  } catch (error) {
    console.error("Error signInApi:", error.message);
  }
};

export const signOutApi = async () => {
  try {
    const response = await api.post("user/auth/sign-out", {}, getConfigWithBearerToken());
    return response.data;
  } catch (error) {
    console.error("Error signOutApi:", error.message);
  }
};

export const getProfileInfoApi = async () => {
  try {
    const response = await api.get("user/profile", getConfigWithBearerToken());
    return response.data;
  } catch (error) {
    console.error("Error getProfileInfoApi:", error.message);
  }
};

export const updateProfileInfoApi = async (updatedProfileData) => {
  try {
    const response = await api.put(
      "user/profile",
      { updatedProfileData },
      getConfigWithBearerToken()
    );
    return response.data;
  } catch (error) {
    console.error("Error getProfileInfoApi:", error.message);
  }
};

export const updateProfileImageApi = async (imageFile) => {
  try {
    const formData = new FormData();
    formData.append('profileImage', imageFile);

    const response = await api.put(
      "user/profile/pfp-image",
      formData,
      getConfigWithBearerToken()
    );
    return response.data;
  } catch (error) {
    console.error("Error updateProfileImageApi:", error.message);
  }
};

export const passwordResetEmailApi = async (userEmail) => {
  try {
    const response = await api.post("/user/security/password-reset-email", { userEmail }, getConfigWithBearerToken());
    return response.data;
  } catch (error) {
    console.error("Error passwordResetEmailApi:", error.message);
  }
};

export const verifyPasswordResetApi = async (userEmail, resetCode) => {
  try {
    const response = await api.post("/user/security/password-reset-verify", { userEmail, resetCode }, getConfigWithBearerToken());
    return response.data;
  } catch (error) {
    console.error("Error verifyPasswordResetApi:", error.message);
  }
};