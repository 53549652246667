import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  FormControl,
  OutlinedInput,
  FormHelperText,
  InputLabel,
  Button,
  useTheme,
  Link,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

import Header from "components/Header";

import { Formik } from "formik";
import * as yup from "yup";
import Logo from "assets/logo.png";
import signInInfo from "assets/sign-in-info.png";

import { useDispatch, useSelector } from "react-redux";
import { signIn, clearStatus, passwordResetEmail, updateResetEmailStatus } from "features/user/userSlice";

const SignIn = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { signInStatus, resetEmailLoading, resetEmailStatus } = useSelector((state) => state.user);

  const [forgotPassword, setForgotPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  const [codeSent, setCodeSent] = useState(false);

  useEffect(() => {
    dispatch(clearStatus());
  }, []);

  useEffect(() => {
    if (signInStatus === "Successful") {
      navigate('/dashboard');
    }
  }, [signInStatus]);

  // Validation schema for the form
  const validationSchema = yup.object({
    email: yup.string().email("Invalid email address").required("Email is required"),
    password: yup.string().required("Password is required"),
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSendCode = () => {
    dispatch(passwordResetEmail(forgotPasswordEmail));
  };

  return (
    <Box sx={{
      height: "100%",
      display: "flex",
      flexDirection: "row",
    }}>
      {/* Left Box */}
      <Box
        sx={{
          width: "45%",
          bgcolor: theme.palette.background.default,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            padding: "2rem",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <img src={Logo} alt="Logo" height="50px" />
          <Header title="Secret Wholesale" />
        </Box>
        <Box
          sx={{
            height: "80%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          {
            forgotPassword ?
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  padding: "16px",
                  borderRadius: "4px",
                  width: "45%",
                  maxWidth: "45%",
                }}
              >
                <Typography variant="h5" fontWeight="bold">
                  Forgot Password
                </Typography>
                <TextField
                  type="email"
                  name="forgotPasswordEmail"
                  label="Email"
                  value={forgotPasswordEmail}
                  onChange={(e) => setForgotPasswordEmail(e.target.value)}
                  required
                  color="secondary"
                />
                <Box sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end"
                }}>
                  <Button
                    variant="text"
                    color="inherit"
                    sx={{
                      margin: "8px 0 12px 0",
                      textDecoration: "underline",
                      textTransform: "none",
                      width: "20%"
                    }}
                    onClick={() => setForgotPassword(false)}
                  >
                    Sign In?
                  </Button>
                </Box>
                {
                  resetEmailStatus === "idle" &&
                  <LoadingButton
                    variant="contained"
                    size="large"
                    fullWidth
                    sx={{
                      color: "black",
                      backgroundColor: "white",
                      textTransform: "none",
                      fontWeight: "bold",
                      border: "2px solid white",
                      "&:hover": {
                        backgroundColor: "transparent",
                        border: "2px solid white",
                        color: "white",
                      },
                    }}
                    onClick={handleSendCode}
                    Loading={resetEmailLoading}
                  >
                    Send Code
                  </LoadingButton>
                }
                {
                  resetEmailStatus !== "idle" &&
                  <LoadingButton
                    variant="contained"
                    size="large"
                    fullWidth
                    sx={{
                      color: "black",
                      backgroundColor: "white",
                      textTransform: "none",
                      fontWeight: "bold",
                      border: "2px solid white",
                      "&:hover": {
                        backgroundColor: "transparent",
                        border: "2px solid white",
                        color: "white",
                      },
                    }}
                    onClick={() => dispatch(updateResetEmailStatus('idle'))}
                  >
                    {
                      resetEmailStatus === "fulfilled" ?
                        "Code Sent!" :
                        "Failed"
                    }
                  </LoadingButton>
                }
              </Box>
              :
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  dispatch(signIn(values));
                }}
              >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                  <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                      padding: "16px",
                      borderRadius: "4px",
                      width: "45%",
                      maxWidth: "45%",
                    }}
                  >
                    <Typography variant="h5" fontWeight="bold">
                      Sign In
                    </Typography>
                    <TextField
                      type="email"
                      name="email"
                      label="Email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      required
                      color="secondary"
                    />
                    <FormControl variant="outlined">
                      <InputLabel color="secondary" htmlFor="outlined-adornment-password">Password</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.password && Boolean(errors.password)}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                        required
                        color="secondary"
                      />
                      {touched.password && errors.password && (
                        <FormHelperText error>{errors.password}</FormHelperText>
                      )}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          alignItems: "flex-end",
                        }}
                      >
                        <Button
                          variant="text"
                          color="inherit"
                          sx={{
                            margin: "12px 0 14px 0",
                            textDecoration: "underline",
                            textTransform: "none",
                          }}
                          // onClick={() => setForgotPassword(true)}
                        >
                          Forgot password?
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          size="large"
                          fullWidth
                          sx={{
                            color: "black",
                            backgroundColor: "white",
                            textTransform: "none",
                            fontWeight: "bold",
                            border: "2px solid white",
                            "&:hover": {
                              backgroundColor: "transparent",
                              border: "2px solid white",
                              color: "white",
                            },
                          }}
                        >
                          {signInStatus || "Sign In"}
                        </Button>

                      </Box>
                    </FormControl>
                  </Box>
                )}
              </Formik>
          }
        </Box>
      </Box>
      <Box
        sx={{
          width: "55%",
          bgcolor: theme.palette.primary[500],
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={signInInfo} height="80%" />
      </Box>
    </Box>
  );
};

export default SignIn;