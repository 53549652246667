import React from 'react';

import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  useTheme
} from '@mui/material';

const DialogBox = ({ open, onClose, onConfirm, onConfirmTwo, titleText, messageText, confirmText, confirmTextTwo }) => {
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <Box
        sx={{
          backgroundColor: theme.palette.primary[400]
        }}
      >
        <DialogTitle id="dialog-title">{titleText}</DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-description">
            {messageText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <div style={{ flex: 1 }}></div>
          {onConfirmTwo && confirmTextTwo && (
            <Button onClick={onConfirmTwo} color="secondary">
              {confirmTextTwo}
            </Button>
          )}
          <Button onClick={onConfirm} color="secondary">
            {confirmText}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

export default DialogBox;