import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  Box,
  Button,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DataGrid } from "@mui/x-data-grid";

import { OpenInNewOutlined } from "@mui/icons-material";

import Header from "components/Header";
import Filter from "components/Filter";
import DialogBox from "components/DialogBox";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";

import { useDispatch, useSelector } from "react-redux";
import {
  getSuppliers,
  getScrapedInventory,
  getScrapedInventoryXlsx,
  deleteScrapedInventory,
  updateRangeFilter,
  updateFilter
} from "features/suppliers/suppliersSlice";

const ScrapedItems = ({ setProgress }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    supplierIdPairs,
    brands,
    itemsStatus,
    items,
    totalResults,
    filters,
    itemsDeleteStatus,
    itemsExportXlsxStatus
  } = useSelector((state) => state.suppliers);

  const [supplier, setSupplier] = useState("");
  const [brand, setBrand] = useState("");
  const [priceRange, setPriceRange] = useState("");
  const [quantityRange, setQuantityRange] = useState("");

  const [customPriceFilterDialogOpen, setCustomPriceFilterDialogOpen] = useState(false);
  const [customQuantityFilterDialogOpen, setCustomQuantityFilterDialogOpen] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const [searchInput, setSearchInput] = useState("");

  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [minQuantity, setMinQuantity] = useState('');
  const [maxQuantity, setMaxQuantity] = useState('');

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [exportDialogOpen, setExportDialogOpen] = useState(false);
  const [filterDeleteDialogOpen, setFilterDeleteDialogOpen] = useState(false);

  const [fullScreenImage, setFullScreenImage] = useState("");

  const [deleteFilter, setDeleteFilter] = useState({
    supplier: "",
    brand: "",
  });

  useEffect(() => {
    if (itemsStatus !== "pending") setProgress(100);
  }, [itemsStatus]);

  useEffect(() => {
    setProgress(0);

    dispatch(getSuppliers({ type: 'id-pairs' }));
    handleGetItems();

    setProgress(26);
  }, [filters, paginationModel]);

  useEffect(() => {
    if (itemsDeleteStatus !== 'pending') handleGetItems();
  }, [itemsDeleteStatus])

  const columns = [
    {
      field: "#",
      headerName: "#",
      flex: 0.4,
    },
    {
      field: "images",
      headerName: "Image",
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <img
            src={`${process.env.REACT_APP_BASE_PREFIX}/itemImages/${params.value[0]}`}
            alt={params.value[0]}
            style={{
              height: '80%',
              objectFit: 'cover',
              borderRadius: '5px',
            }}
            onClick={() => setFullScreenImage(`${process.env.REACT_APP_BASE_PREFIX}/itemImages/${params.value[0]}`)}
          />
        </Box>
      )
    },
    {
      field: "supplier",
      headerName: "Supplier",
      flex: 1,
    },
    {
      field: "brand",
      headerName: "Brand",
      flex: 1,
    },
    {
      field: "sku",
      headerName: "SKU",
      flex: 1,
      renderCell: (params) => (
        <a
          href={params.row.url}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: theme.palette.mode === "dark" ? "#FAFAFC" : "#4F4F4F" }}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <span>{params.value}</span>
        </a>
      ),
    },
    {
      field: "title",
      headerName: "Title",
      flex: 3,
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <Link to={`/scraped items/${params.row._id}`}>
          <OpenInNewOutlined color="secondary" />
        </Link>
      ),
    }
  ];

  const quantityFilter = [
    { option: "1 to 5", value: "1-5" },
    { option: "6 to 10", value: "6-10" },
    { option: "11 to 15", value: "11-15" },
    { option: "16 to 30", value: "16-30" },
    { option: "31 to max", value: "31-max" },
  ];

  const priceFilter = [
    { option: "$1 to $10", value: "1-10" },
    { option: "$11 to $50", value: "11-50" },
    { option: "$51 to $100", value: "51-100" },
    { option: "$101 to $1000", value: "101-1000" },
    { option: "$1001 to max", value: "1001-max" },
  ];

  const handlePriceFilterContinue = () => {
    dispatch(updateRangeFilter({ type: "price", value: `${minPrice}-${maxPrice}` }));
    setCustomPriceFilterDialogOpen(false);
  };

  const handleQuantityFilterContinue = () => {
    dispatch(updateRangeFilter({ type: "quantity", value: `${minQuantity}-${maxQuantity}` }));
    setCustomQuantityFilterDialogOpen(false);
  };

  const handleSupplierChange = (supplier) => {
    setSupplier(supplier);
    dispatch(updateFilter({ type: "supplier", value: supplier }));
  };

  const handleBrandChange = (brand) => {
    setBrand(brand);
    dispatch(updateFilter({ type: "brand", value: brand }));
  };

  const handlePriceRangeChange = (range) => {
    setPriceRange(range);
    dispatch(updateRangeFilter({ type: "price", value: range }));
  };

  const handleQuantityRangeChange = (range) => {
    setQuantityRange(range);
    dispatch(updateRangeFilter({ type: "quantity", value: range }));
  };

  const handleGetItems = () => {
    dispatch(getScrapedInventory({
      page: paginationModel.page + 1,
      pageSize: paginationModel.pageSize,
      search: searchInput,
      filters
    }));
  };

  const handlePriceRange = (value) => {
    if (value === "custom") {
      setPriceRange("custom");
      setCustomPriceFilterDialogOpen(true);
    } else {
      handlePriceRangeChange(value);
    }
  };

  const handleQuantityRange = (value) => {
    if (value === "custom") {
      setQuantityRange("custom");
      setCustomQuantityFilterDialogOpen(true);
    } else {
      handleQuantityRangeChange(value);
    }
  };

  const handleDeleteSelected = () => {
    dispatch(deleteScrapedInventory({ type: 'ids', data: selectedItems }));

    setDeleteDialogOpen(false);
  };

  const handleFilterDelete = () => {
    dispatch(deleteScrapedInventory({ type: 'filter', data: deleteFilter }));

    setFilterDeleteDialogOpen(false);
  };

  const handleExport = () => {
    dispatch(getScrapedInventoryXlsx({
      search: searchInput,
      filters,
      selectedItems
    }));

    setExportDialogOpen(false);
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Scraped Items" subtitle="Manage Supplier Items" />
      <Box
        m="1.5rem 0 0.5rem 0"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: '30%'
          }}
        >
          <Filter
            type="supplier"
            label="Supplier"
            filter={
              supplierIdPairs.map((obj) => {
                return {
                  option: obj.supplierNonCamelCase,
                  value: obj._id
                }
              })
            }
            custom={false}
            value={supplier}
            onChange={(e) => handleSupplierChange(e.target.value)}
          />
          <Filter
            type="brand"
            label="Brand"
            filter={
              brands.map((brand) => {
                return {
                  option: brand,
                  value: brand
                }
              })
            }
            custom={false}
            value={brand}
            onChange={(e) => handleBrandChange(e.target.value)}
          />
          <Filter
            type="price"
            label="Price"
            filter={priceFilter}
            custom={true}
            value={priceRange}
            onChange={(e) => handlePriceRange(e.target.value)}
          />
          <Filter
            type="quantity"
            label="Quantity"
            filter={quantityFilter}
            custom={true}
            value={quantityRange}
            onChange={(e) => handleQuantityRange(e.target.value)}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            width: '30%'
          }}
        >
          <LoadingButton
            color="secondary"
            variant="contained"
            sx={{
              width: "50%",
              padding: "15px 15px",
              alignSelf: "flex-end",
              whiteSpace: 'nowrap',
              fontSize: '11px',
            }}
            onClick={() => setExportDialogOpen(true)}
            loading={itemsExportXlsxStatus === "pending"}
          >Items Export
          </LoadingButton>
        </Box>
      </Box>
      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.primary[500],
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.primary[500],
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          sx={{
            "& .MuiDataGrid-cell": {
              borderBottom: `1px solid ${theme.palette.secondary[200]} !important`
            },
            "& .MuiDataGrid-checkboxInput": {
              color: `${theme.palette.secondary[400]} !important`,
            },
          }}
          loading={itemsStatus === "pending"}
          getRowId={(row) => row._id}
          rows={items || []}
          rowCount={totalResults}
          columns={columns}
          pageSizeOptions={[25, 50, 100]}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          components={{
            Toolbar: DataGridCustomToolbar,
          }}
          componentsProps={{
            toolbar: {
              searchInput,
              setSearchInput,
              setDeleteDialogOpen,
              setFilterDeleteDialogOpen,
              handleGet: handleGetItems
            }
          }}
          checkboxSelection
          onRowSelectionModelChange={(newSelection) => {
            setSelectedItems(newSelection);
          }}
          rowSelectionModel={selectedItems}
          disableRowSelectionOnClick
        />
      </Box>
      <Dialog
        open={customPriceFilterDialogOpen}
        onClose={() => setCustomPriceFilterDialogOpen(false)}
        aria-labelledby="custom-filter-dialog-title"
        aria-describedby="custom-filter-dialog-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.primary[400]
          }}
        >
          <DialogTitle id="custom-filter-dialog-title">Custom Filter</DialogTitle>
          <DialogContent>
            <Box
              sx={{
                display: 'flex',
                flexDirection: "column",
                gap: "12px"
              }}
            >
              <DialogContentText id="custom-filter-dialog-description">
                Enter Min and Max price values to filter by price range.
              </DialogContentText>
              <TextField
                color="secondary"
                variant="outlined"
                id="min-price"
                label="Min. Price"
                value={minPrice}
                onChange={(event) => setMinPrice(event.target.value)}
                fullWidth
              />
              <TextField
                color="secondary"
                variant="outlined"
                id="max-price"
                label="Max. Price"
                value={maxPrice}
                onChange={(event) => setMaxPrice(event.target.value)}
                fullWidth
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCustomPriceFilterDialogOpen(false)} color="secondary">
              Cancel
            </Button>
            <Button onClick={handlePriceFilterContinue} color="secondary">
              Continue
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Dialog
        open={customQuantityFilterDialogOpen}
        onClose={() => setCustomQuantityFilterDialogOpen(false)}
        aria-labelledby="custom-filter-dialog-title"
        aria-describedby="custom-filter-dialog-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.primary[400]
          }}
        >
          <DialogTitle id="custom-filter-dialog-title">Custom Filter</DialogTitle>
          <DialogContent>
            <Box
              sx={{
                display: 'flex',
                flexDirection: "column",
                gap: "12px"
              }}
            >
              <DialogContentText id="custom-filter-dialog-description">
                Enter Min and Max quantity values to filter by quantity range.
              </DialogContentText>
              <TextField
                color="secondary"
                variant="outlined"
                id="min-quantity"
                label="Min. Quantity"
                value={minQuantity}
                onChange={(event) => setMinQuantity(event.target.value)}
                fullWidth
              />
              <TextField
                color="secondary"
                variant="outlined"
                id="max-quantity"
                label="Max. Quantity"
                value={maxQuantity}
                onChange={(event) => setMaxQuantity(event.target.value)}
                fullWidth
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCustomQuantityFilterDialogOpen(false)} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleQuantityFilterContinue} color="secondary">
              Continue
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <DialogBox
        open={exportDialogOpen}
        onClose={() => setExportDialogOpen(false)}
        onConfirm={handleExport}
        titleText="Export Confirmation"
        messageText="Are you sure you wish to export these items?"
        confirmText="Confirm Export"
      />
      <DialogBox
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={handleDeleteSelected}
        titleText="Delete Confirmation"
        messageText="Are you sure you wish to delete these listings?"
        confirmText="Confirm Delete"
      />
      <Dialog
        open={filterDeleteDialogOpen}
        onClose={() => setFilterDeleteDialogOpen(false)}
        aria-labelledby="delete-filter-dialog-title"
        aria-describedby="delete-filter-dialog-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.primary[400]
          }}
        >
          <DialogTitle id="delete-filter-dialog-title">Custom Delete Filter</DialogTitle>
          <DialogContent>
            <Box
              sx={{
                display: 'flex',
                flexDirection: "column",
                gap: "12px"
              }}
            >
              <DialogContentText id="delete-filter-dialog-description">
                Enter store and supplier to use detele by filtering.
              </DialogContentText>
              <Filter
                type="supplier"
                label="Supplier"
                filter={
                  supplierIdPairs.map((obj) => {
                    return {
                      option: obj.supplierNonCamelCase,
                      value: obj._id
                    }
                  })
                }
                custom={false}
                value={deleteFilter.supplier}
                onChange={(e) => setDeleteFilter(prev => ({
                  ...prev,
                  supplier: e.target.value
                }))}
              />
              <Filter
                type="brand"
                label="Brand"
                filter={
                  brands.map((brand) => {
                    return {
                      option: brand,
                      value: brand
                    }
                  })
                }
                custom={false}
                value={deleteFilter.brand}
                onChange={(e) => setDeleteFilter(prev => ({
                  ...prev,
                  brand: e.target.value
                }))}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setFilterDeleteDialogOpen(false)} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleFilterDelete} color="secondary">
              Continue
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      {
        fullScreenImage && (
          <div className="full-screen-image-overlay">
            <div className="full-screen-image">
              <img
                src={fullScreenImage}
                alt="Full Screen Image"
                style={{
                  height: '500px',
                  objectFit: 'cover',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}
              />
              <Button
                variant="contained"
                component="span"
                color="secondary"
                sx={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  borderRadius: "5px"
                }}
                onClick={() => setFullScreenImage("")}
              >Close</Button>
            </div>
          </div>
        )
      }
    </Box>
  );
};

export default ScrapedItems;