import api from "state/api";
import { getConfigWithBearerToken } from "state/api";

export const getListingsApi = async (page, pageSize, search, filters) => {
  try {
    const response = await api.post(
      `listings?searchTerm=${search}&page=${page}&pageSize=${pageSize}`,
      { filters },
      getConfigWithBearerToken()
    );
    return response.data;
  } catch (error) {
    console.error("Error getListingsApi:", error.message);
  }
};

export const getListingsXlsxApi = async (search, filters) => {
  try {
    const response = await api.post(
      `listings/xlsx?searchTerm=${search}`,
      { filters },
      {
        responseType: 'blob',
        ...getConfigWithBearerToken()
      }
    );

    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "listings.xlsx";
    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error getListingsXlsxApi:", error.message);
  }
};

export const updateListingsFromXlsxApi = async (file) => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const response = await api.put("listings/xlsx", formData, {
      headers: {
        ...getConfigWithBearerToken().headers,
        'Content-Type': 'multipart/form-data'
      }
    });

    return response.data;
  } catch (error) {
    console.error("Error updateListingsFromXlsxApi:", error.message);
  }
};

export const getListingApi = async (listingId) => {
  try {
    const response = await api.get(`listings/${listingId}`, getConfigWithBearerToken());
    return response.data;
  } catch (error) {
    console.error("Error getListingApi:", error.message);
  }
};

export const updateListingApi = async (listingId, updatedListing) => {
  try {
    const response = await api.put(
      `listings/${listingId}`,
      { updatedListing },
      getConfigWithBearerToken()
    );
    return response.data;
  } catch (error) {
    console.error("Error updateListingApi:", error.message);
  }
};

export const deleteListingApi = async (type, data) => {
  try {
    const response = await api.post(
      `listings/delete/${type}`,
      { data },
      getConfigWithBearerToken()
    );
    return response.data;
  } catch (error) {
    console.error("Error deleteListing:", error.message);
  }
};

export const syncListingsApi = async () => {
  try {
    const response = await api.get(`listings/sync`, getConfigWithBearerToken());
    return response.data;
  } catch (error) {
    console.error("Error syncListingsApi:", error.message);
  }
};