import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  TextField,
  useTheme,
  Typography,
  Divider,
  Grid
} from "@mui/material";

import Header from "components/Header";
import SubHeader from "components/SubHeader";
import DialogBox from "components/DialogBox";

import { useDispatch, useSelector } from "react-redux";
import {
  getScrapedItem,
} from "features/suppliers/suppliersSlice";

const ScrapedItemEdit = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    scrapedItemInfo
  } = useSelector((state) => state.suppliers);

  const { scrapedItemId } = useParams();

  const [item, setItem] = useState({
    sku: "",
    url: ""
  });
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [quantity, setQuantity] = useState("");
  const [brand, setBrand] = useState("");
  const [images, setImages] = useState([]);
  const [info, setInfo] = useState({});

  useEffect(() => {
    dispatch(getScrapedItem(scrapedItemId));
  }, [])

  useEffect(() => {
    if (scrapedItemInfo) {
      setTitle(scrapedItemInfo?.title || "");
      setDescription(scrapedItemInfo?.description || "");
      setPrice(scrapedItemInfo?.price || "");
      setQuantity(scrapedItemInfo?.quantity || "");
      setBrand(scrapedItemInfo?.brand || "");
      setImages(scrapedItemInfo?.images || "");
      setInfo(scrapedItemInfo?.info || "");
      setItem({
        sku: scrapedItemInfo?.sku,
        url: scrapedItemInfo?.url,
      });
    }
  }, [scrapedItemInfo]);

  return (
    <Box m="1.5rem 2.5rem" pb="2rem">
      <Header
        title={
          <span>
            Scraped Item (
            <a
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: theme.palette.secondary[200] }}
            >
              {item.sku}
            </a>
            )
          </span>
        }
        subtitle="Edit And Manage Your Scraped item"
      />
      <Box
        mt="3rem"
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "75%",
          gap: "2rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <SubHeader title="General Info" />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "60%",
              borderRadius: "10px",
              bgcolor: theme.palette.background.alt,
              padding: "2rem",
              gap: "2rem"
            }}
          >
            <Box>
              <Typography variant="h6" p="3px" mb="10px">Title</Typography>
              <TextField
                id="outlined-read-only-input"
                label={`Title (${title.length})`}
                value={title}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
                color="secondary"
              />
            </Box>
            <Box>
              <Typography variant="h6" p="3px" mb="10px">Supplier Description</Typography>
              <TextField
                id="outlined-multiline-static"
                label="Supplier Description"
                multiline
                value={description}
                InputProps={{
                  readOnly: true,
                }}
                rows={4}
                fullWidth
                color="secondary"
                placeholder="Supplier description..."
                sx={{
                  '& textarea': {
                    resize: 'vertical',  // Allows vertical resizing only
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%"
              }}
            >
              <TextField
                id="outlined-read-only-input"
                label="SKU"
                value={item.sku}
                InputProps={{
                  readOnly: true,
                }}
                color="secondary"
                sx={{
                  width: "45%"
                }}
              />
              <TextField
                id="outlined-read-only-input"
                label="Brand"
                value={brand}
                InputProps={{
                  readOnly: true,
                }}
                color="secondary"
                sx={{
                  width: "45%"
                }}
              />
            </Box>
            <Divider middle />
            <Box>
              <Typography variant="h6" p="3px" mb="10px">Price & Quantity</Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%"
                }}
              >
                <TextField
                  id="outlined-read-only-input"
                  label={`Price`}
                  value={price}
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={{
                    width: "45%"
                  }}
                  color="secondary"
                />
                <TextField
                  id="outlined-read-only-input"
                  label={`Quantity`}
                  value={quantity}
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={{
                    width: "45%"
                  }}
                  color="secondary"
                />
              </Box>
            </Box>
            <Divider middle />
            <Box>
              <Typography variant="h6" p="3px" mb="10px">More Info</Typography>
              <Box
                sx={{
                  width: "100%"
                }}
              >
                {info?.msrp &&
                  <TextField
                    id="outlined-read-only-input"
                    label="MSRP"
                    value={info.msrp}
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{
                      width: "45%"
                    }}
                    color="secondary"
                  />
                }
              </Box>
              <Grid container spacing={2} mt="20px">
                {
                  (info?.inventory && info?.inventory?.length > 0) &&
                  info.inventory.map((location, index) => (
                    <Grid item key={index} xs={6} sx={{ paddingRight: '5%' }}>
                      <TextField
                        id={`outlined-read-only-input-${index}`}
                        label={`Location ${index + 1} (${location.location})`}
                        value={`${location.location}: ${location.quantity}`}
                        InputProps={{
                          readOnly: true,
                        }}
                        sx={{
                          width: "100%",
                        }}
                        color="secondary"
                      />
                    </Grid>
                  ))
                }
              </Grid>
            </Box>
            <Divider middle />
            <Box>
              <Typography variant="h6" p="3px" mb="10px">Item Images</Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                  gap: '10px',
                }}
              >
                {images &&
                  images.length > 0 &&
                  images.map((imageUrl, index) => (
                    <Box
                      sx={{
                        width: '30%',
                        height: '10rem',
                      }}
                    >
                      <img
                        key={index}
                        src={`${process.env.REACT_APP_BASE_PREFIX}/itemImages/${imageUrl}`}
                        alt={imageUrl}
                        style={{
                          height: '100%',
                          objectFit: 'cover',
                          borderRadius: '5px',
                          marginBottom: '10px',
                        }}
                      />
                    </Box>
                  ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default ScrapedItemEdit;
