import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  Box,
  useTheme,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { OpenInNewOutlined, AttachMoney } from "@mui/icons-material";

import Header from "components/Header";

import { useDispatch, useSelector } from "react-redux";
import { getSuppliers, resetSupplierInfo } from "features/suppliers/suppliersSlice";

const Suppliers = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { totalSuppliers, suppliers, suppliersStatus } = useSelector((state) => state.suppliers);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);

  useEffect(() => {
    dispatch(resetSupplierInfo());
    dispatch(getSuppliers({ type: "list", page: page + 1, pageSize }));
  }, [page, pageSize])

  const columns = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "supplier", headerName: "Supplier", flex: 2 },
    { field: "lastInventoryScraped", headerName: "Last Scraped Date", flex: 2 },
    { field: "skuPrefix", headerName: "Sku Prefix", flex: 2 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
         <Box
          sx={{
            width: "100%",
            '& > *': {
              margin: '0 8px',
            },
          }}
        >
          <Link to={`/suppliers/${params.row._id}`}>
            <OpenInNewOutlined color="secondary" />
          </Link>
          {/* <Link to={`/suppliers/${params.row._id}/pricing setup`}>
            <AttachMoney color="secondary" />
          </Link> */}
        </Box>
      ),
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Suppliers" subtitle="Manage Your Suppliers" />
      <Box
        mt="15px"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.primary[500],
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.primary[500],
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          sx={{
            "& .MuiDataGrid-cell": {
              borderBottom: `1px solid ${theme.palette.secondary[200]} !important`
            },
          }}
          className="custom-data-grid"
          loading={suppliersStatus === "pending"}
          rows={suppliers || []}
          rowCount={totalSuppliers}
          columns={columns}
          rowsPerPageOptions={[1, 20, 50, 100]}
          pagination
          paginationMode="server"
          page={page}
          pageSize={pageSize}
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        />
      </Box>
    </Box>
  );
};

export default Suppliers;