import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box } from "@mui/material";

import Header from "components/Header";
import DialogBox from "components/DialogBox";
import StoreForm from "components/StoreForm";

import { useDispatch, useSelector } from "react-redux";
import { createStore } from "features/stores/storesSlice";

const CreateStore = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const { createStoreStatus } = useSelector((state) => state.stores);

  const [suppliers, setSuppliers] = useState([]);
  const [formValues, setFormValues] = useState({
    storeName: "",
    storeEmail: "",
    storePassword: "",
    storeRedirectUri: ""
  });

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const handleFormSubmit = () => {
    dispatch(createStore({ ...formValues, supplierIds: suppliers }));
    setConfirmDialogOpen(false)
    navigate('/stores');
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Add Store" subtitle="Add A New ebay Store" />
      <Box m="3rem 0">
        <StoreForm
          type={'create'}
          formValues={formValues}
          setFormValues={setFormValues}
          suppliers={suppliers}
          setSuppliers={setSuppliers}
          setConfirmDialogOpen={setConfirmDialogOpen}
          storeStatus={createStoreStatus}
          confirmText={"Add New Store"}
          passwordPlaceholder={"Password ( eBay store password )"}
        />
      </Box>
      <DialogBox
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={handleFormSubmit}
        titleText="Add Store Confirmation"
        messageText="Are you sure you want to link this store?"
        confirmText="Add Store"
      />
    </Box>
  );
};

export default CreateStore;