import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getStoresApi,
  getStoreInfoApi,
  updateStoreInfoApi,
  createStoreApi,
  updateFetchSwitchApi,
  itemIdFileFormatApi,
  getSourcingSetupApi,
  updateSourcingSetupApi,
  updateStoreSyncSwitchApi,
  deleteStoreApi,
  editStoreInfoApi
} from "./storesApi";
import { notifyMsg } from "state/api";

export const getStores = createAsyncThunk("stores/getStores", async ({ type, page, pageSize }) => {
  const response = await getStoresApi(type, page, pageSize);
  return response;
});

export const getStoreInfo = createAsyncThunk("stores/getStoreInfo", async (storeId) => {
  const response = await getStoreInfoApi(storeId);
  return response;
});

export const getSourcingSetup = createAsyncThunk("stores/getSourcingSetup", async (storeId) => {
  const response = await getSourcingSetupApi(storeId);
  return response;
});

export const updateSourcingSetup = createAsyncThunk("stores/updateSourcingSetup", async ({ storeId, sourcingSetup }) => {
  const response = await updateSourcingSetupApi(storeId, sourcingSetup);
  notifyMsg(response);
  return response;
});

export const updateStoreInfo = createAsyncThunk("stores/updateStoreInfo", async ({ storeId, accessToken, supplierMarkUps, syncSwitch }) => {
  const response = await updateStoreInfoApi(storeId, accessToken, supplierMarkUps, syncSwitch);
  notifyMsg(response);
  return response;
});

export const editStoreInfo = createAsyncThunk("stores/editStoreInfo", async ({ storeId, info }) => {
  const response = await editStoreInfoApi(storeId, info);
  notifyMsg(response);
  return response;
});

export const deleteStore = createAsyncThunk("stores/deleteStore", async (storeId) => {
  const response = await deleteStoreApi(storeId);
  notifyMsg(response);
  return response;
});

export const updateStoreSyncSwitch = createAsyncThunk("stores/updateStoreSyncSwitch", async ({ storeId, syncSwitch }) => {
  const response = await updateStoreSyncSwitchApi(storeId, syncSwitch);
  notifyMsg(response);
  return response;
});

export const createStore = createAsyncThunk("stores/createStore", async (credentials) => {
  const response = await createStoreApi(credentials);
  notifyMsg(response);
  return response;
});

export const updateFetchSwitch = createAsyncThunk("stores/updateFetchSwitch", async ({ storeId, fetchSwitch, specifiedItemIds, itemIdsFile }) => {
  const response = await updateFetchSwitchApi(storeId, fetchSwitch, specifiedItemIds, itemIdsFile);
  return response;
});

export const itemIdFileFormat = createAsyncThunk("stores/itemIdFileFormat", async () => {
  const response = await itemIdFileFormatApi();
  return response;
});

const initialState = {
  updateStatus: "",
  createStoreStatus: "",
  fetchStatus: "",
  itemIdFileFormatStatus: "",
  totalStoresResults: 0,
  storeIdPairs: [],
  stores: [],
  storesStatus: "",
  deleteStatus: "",
  updateStoreSourcingSetupStatus: "",
  storeSourcingSetup: {},
  storeInfo: {},
};

const storesSlice = createSlice({
  name: "storesSlice",
  initialState,
  reducers: {
    resetStoreInfo: (state) => {
      state.storeInfo = {};
      state.storeSourcingSetup = {};
      state.updateStoreSourcingSetupStatus = "";
    }
  },
  extraReducers: (builder) => {
    builder
      // createStore
      .addCase(createStore.pending, (state) => {
        state.createStoreStatus = "pending";
      })
      .addCase(createStore.fulfilled, (state, { payload }) => {
        state.createStoreStatus = "fulfilled";
      })
      .addCase(createStore.rejected, (state) => {
        state.createStoreStatus = "rejected";
      })
      // getStores
      .addCase(getStores.pending, (state) => {
        state.storesStatus = "pending";
      })
      .addCase(getStores.fulfilled, (state, { payload }) => {
        if (payload.data.type === "id-pairs") {
          state.storeIdPairs = payload.data.stores;
        } else {
          state.stores = payload.data.stores;
          state.totalStoresResults = payload.data.totalStoresResults;
          state.storesStatus = "fulfilled";
        }
      })
      // getStoreInfo
      .addCase(getStoreInfo.fulfilled, (state, { payload }) => {
        if (payload) {
          state.storeInfo = payload.data;
        }
      })
      // updateStoreInfo
      .addCase(updateStoreInfo.pending, (state) => {
        state.updateStatus = "pending";
      })
      .addCase(updateStoreInfo.fulfilled, (state, { payload }) => {
        state.updateStatus = "fulfilled";
      })
      .addCase(updateStoreInfo.rejected, (state) => {
        state.updateStatus = "rejected";
      })
      // editStoreInfo
      .addCase(editStoreInfo.pending, (state) => {
        state.updateStatus = "pending";
      })
      .addCase(editStoreInfo.fulfilled, (state, { payload }) => {
        state.updateStatus = "fulfilled";
      })
      .addCase(editStoreInfo.rejected, (state) => {
        state.updateStatus = "rejected";
      })
      // deleteStore
      .addCase(deleteStore.pending, (state) => {
        state.deleteStatus = "pending";
      })
      .addCase(deleteStore.fulfilled, (state) => {
        state.deleteStatus = "fulfilled";
      })
      .addCase(deleteStore.rejected, (state) => {
        state.deleteStatus = "rejected";
      })
      // updateFetchSwitch
      .addCase(updateFetchSwitch.pending, (state) => {
        state.fetchStatus = "pending";
      })
      .addCase(updateFetchSwitch.fulfilled, (state, { payload }) => {
        state.fetchStatus = "fulfilled";
      })
      .addCase(updateFetchSwitch.rejected, (state) => {
        state.fetchStatus = "rejected";
      })
      // itemIdFileFormat
      .addCase(itemIdFileFormat.pending, (state) => {
        state.itemIdFileFormatStatus = "pending";
      })
      .addCase(itemIdFileFormat.fulfilled, (state) => {
        state.itemIdFileFormatStatus = "fulfilled";
      })
      .addCase(itemIdFileFormat.rejected, (state) => {
        state.itemIdFileFormatStatus = "rejected";
      })
      // getSourcingSetup
      .addCase(getSourcingSetup.fulfilled, (state, { payload }) => {
        state.storeSourcingSetup = payload.data.sourcingSetup;
      })
      // updateSourcingSetup
      .addCase(updateSourcingSetup.pending, (state) => {
        state.updateStoreSourcingSetupStatus = "pending";
      })
      .addCase(updateSourcingSetup.fulfilled, (state) => {
        state.updateStoreSourcingSetupStatus = "fulfilled";
      })
      .addCase(updateSourcingSetup.rejected, (state) => {
        state.updateStoreSourcingSetupStatus = "rejected";
      });
  },
});

export const { resetStoreInfo } = storesSlice.actions;
export default storesSlice.reducer;
