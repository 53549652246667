import api from "state/api";
import { getConfigWithBearerToken } from "state/api";

export const getStoresApi = async (type, page, pageSize) => {
  try {
    const response = await api.get(`/stores/config?type=${type}&page=${page}&pageSize=${pageSize}`, getConfigWithBearerToken());
    return response.data;
  } catch (error) {
    console.error("Error getStoresApi:", error.message);
  }
};

export const getStoreInfoApi = async (storeId) => {
  try {
    const response = await api.get(`/stores/config/${storeId}`, getConfigWithBearerToken());
    return response.data;
  } catch (error) {
    console.error("Error getStoreInfoApi:", error.message);
  }
};

export const getSourcingSetupApi = async (storeId) => {
  try {
    const response = await api.get(`/stores/sourcing-setup/${storeId}`, getConfigWithBearerToken());
    return response.data;
  } catch (error) {
    console.error("Error getSourcingSetupApi:", error.message);
  }
};

export const updateSourcingSetupApi = async (storeId, sourcingSetup) => {
  try {
    const response = await api.put(
      `/stores/sourcing-setup/${storeId}`,
      { sourcingSetup },
      getConfigWithBearerToken()
    );
    return response.data;
  } catch (error) {
    console.error("Error updateSourcingSetupApi:", error.message);
  }
};

export const updateStoreInfoApi = async (storeId, accessToken, supplierMarkUps, syncSwitch) => {
  try {
    const response = await api.put(
      `/stores/config/${storeId}`,
      { accessToken, supplierMarkUps, syncSwitch },
      getConfigWithBearerToken()
    );
    return response.data;
  } catch (error) {
    console.error("Error updateStoreInfoApi:", error.message);
  }
};

export const deleteStoreApi = async (storeId) => {
  try {
    const response = await api.delete(
      `/stores/config/${storeId}`,
      getConfigWithBearerToken()
    );
    return response.data;
  } catch (error) {
    console.error("Error deleteStoreApi:", error.message);
  }
};

export const updateStoreSyncSwitchApi = async (storeId, syncSwitch) => {
  try {
    const response = await api.put(
      `/stores/config/sync-switch/${storeId}`,
      { syncSwitch },
      getConfigWithBearerToken()
    );
    return response.data;
  } catch (error) {
    console.error("Error updateStoreSyncSwitchApi:", error.message);
  }
};

export const createStoreApi = async (credentials) => {
  try {
    const response = await api.post("/stores/config/create", credentials, getConfigWithBearerToken());
    return response.data;
  } catch (error) {
    console.error("Error createStoreApi:", error.message);
  }
};

export const editStoreInfoApi = async (storeId, info) => {
  try {
    const response = await api.put(`/stores/config/edit/${storeId}`, info, getConfigWithBearerToken());
    return response.data;
  } catch (error) {
    console.error("Error editStoreInfoApi:", error.message);
  }
};

export const updateFetchSwitchApi = async (storeId, fetchSwitch, specifiedItemIds, itemIdsFile) => {
  try {
    const formData = new FormData();
    formData.append('fetchSwitch', fetchSwitch);
    formData.append('specifiedItemIds', specifiedItemIds);
    formData.append('file', itemIdsFile);

    const response = await api.put(
      `/stores/config/fetch/${storeId}`,
      formData,
      getConfigWithBearerToken({
        headers: {
          'Content-Type': 'multipart/form-data', // Important for file uploads
        },
      })
    );

    return response.data;
  } catch (error) {
    console.error("Error updateFetchSwitchApi:", error.message);
  }
};

export const itemIdFileFormatApi = async () => {
  try {
    const response = await api.get(`stores/config/fetch/file-format`, {
      responseType: 'blob',
      ...getConfigWithBearerToken()
    });

    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "itemIds-format.xlsx";
    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error itemIdFileFormatApi:', error.message);
  }
};