import api from "state/api";
import { getConfigWithBearerToken } from "state/api";

export const updateDevConfigApi = async (credentials) => {
  try {
    const response = await api.put(
      "/marketplaces/ebay/dev-config",
      credentials,
      getConfigWithBearerToken()
    );
    return response.data;
  } catch (error) {
    console.error("Error updateDevConfigApi:", error.message);
  }
};

export const getDevConfigApi = async () => {
  try {
    const response = await api.get(`/marketplaces/ebay/dev-config`, getConfigWithBearerToken());
    return response.data;
  } catch (error) {
    console.error("Error getDevConfigApi:", error.message);
  }
};

export const updateAuthorizatioCodeApi = async (storeId, code) => {
  try {
    const response = await api.post(`/marketplaces/ebay/authorizatio-code?code=${code}&storeId=${storeId}`, {}, getConfigWithBearerToken());
    return response.data;
  } catch (error) {
    console.error("Error updateAuthorizatioCodeApi:", error.message);
  }
};

export const fetchEbayCategoriesApi = async (storeId) => {
  try {
    const response = await api.get(`/marketplaces/ebay/categories/${storeId}`, getConfigWithBearerToken());
    return response.data;
  } catch (error) {
    console.error("Error getEbayCategoriesApi:", error.message);
  }
};

export const getCategoryApi = async (categoryIds) => {
  try {
    const response = await api.get(`/marketplaces/ebay/category?categoryIds=${categoryIds ? categoryIds : ""}`, getConfigWithBearerToken());
    return response.data;
  } catch (error) {
    console.error("Error getCategoryApi:", error.message);
  }
};

export const getItemSpecificsApi = async () => {
  try {
    const response = await api.get(`/marketplaces/ebay/item-specifics`, getConfigWithBearerToken());
    return response.data;
  } catch (error) {
    console.error("Error getItemSpecificsApi:", error.message);
  }
};

export const updateItemSpecificsApi = async (itemSpecifics) => {
  try {
    const response = await api.put(
      `/marketplaces/ebay/item-specifics`,
      { itemSpecifics },
      getConfigWithBearerToken()
    );
    return response.data;
  } catch (error) {
    console.error("Error updateItemSpecificsApi:", error.message);
  }
};

export const addListingsApi = async (storeId, listings, itemSpecifics) => {
  try {
    const response = await api.post(
      `/marketplaces/ebay/add-listings/${storeId}`,
      { listings, itemSpecifics },
      getConfigWithBearerToken()
    );
    return response.data;
  } catch (error) {
    console.error("Error addListingsApi:", error.message);
  }
};

export const formatUploadApi = async(storeId, file) => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const response = await api.post(`/marketplaces/ebay/format-upload/${storeId}`,
      formData,
      {
        headers: {
          ...getConfigWithBearerToken().headers,
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error formatUploadApi:", error.message);
  }
};