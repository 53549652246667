import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";

import {
  Box,
  Button,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Tooltip,
  ImageList,
  ImageListItem,
  Fab
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  DataGrid,
  GridToolbarDensitySelector,
  GridToolbarContainer,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";

import {
  Search,
  CollectionsOutlined,
  AttachMoneyOutlined,
  NumbersOutlined,
  AddOutlined,
  Delete,
  ArrowBackIosNew,
  TuneOutlined,
  Lock,
  LockOpenOutlined,
  FileUploadOutlined
} from "@mui/icons-material";

import { TextareaAutosize } from '@mui/base/TextareaAutosize';

import Header from "components/Header";
import Filter from "components/Filter";
import DialogBox from "components/DialogBox";
import FlexBetween from "components/FlexBetween";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchEbayCategories,
  getCategory,
  resetCategory,
  getItemSpecifics,
  updateItemSpecifics,
  formatUpload,
  addListings
} from "features/marketplaces/marketplacesSlice";

const CreateListings = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    categories: fetchedCategories,
    subcategories: fetchedSubcategories,
    categoryString: fetchedCategoryString,
    lastSubcategory: fetchedLastSubcategory,
    itemSpecifics: fetchedItemSpecifics,
    formatUploadStatus,
    formatedUpload,
    addListingsStatus
  } = useSelector((state) => state.marketplaces);

  const { storeId } = useParams();

  const [listings, setListings] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const [listing, setListing] = useState({});
  const [listingId, setListingId] = useState("");
  const [listingCategoryId, setListingCategoryId] = useState("select");
  const [listingCategoryIds, setListingCategoryIds] = useState([]);

  const [categoryString, setCategoryString] = useState("");
  const [subcategories, setSubcategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [lastSubcategory, setLastSubcategory] = useState(false);

  const [itemSpecifics, setItemSpecifics] = useState([]);

  const [images, setImages] = useState([]);
  const [hovering, setHovering] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const [searchInput, setSearchInput] = useState("");

  const [categoryDialogOpen, setCategoryDialogOpen] = useState(false);
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [itemSpecificsDialogOpen, setItemSpecificsDialogOpen] = useState(false);
  const [itemDetailsDialogOpen, setItemDetailsDialogOpen] = useState(false);
  const [uploadItemsDialogOpen, setUploadItemsDialogOpen] = useState(false);
  const [addListingsDialogOpen, setAddListingsDialogOpen] = useState(false);

  const columns = [
    {
      field: "id",
      headerName: "#",
      flex: 0.2,
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      renderCell: (params) => (
        <Tooltip
          title={params.value?.name ? params.value.name : "Select Category"}
        >
          <Button
            sx={{
              color: "#fff",
              background: 'transparent',
            }}
            onClick={() => handleCategoryDialogOpen(params.row.id, params.value?.id)}
          >
            <div
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '200px',
              }}
            >
              {params.value?.name ? params.value.name : "Select Category"}
            </div>
          </Button>
        </Tooltip>
      )
    },
    {
      field: "images",
      headerName: "Image",
      flex: 0.5,
      renderCell: (params) => (
        <Tooltip title={`Images ${params.value.length}`}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative", // Add this to the parent Box
            }}
          >
            {params.value.length > 0 ? (
              <div
                onMouseEnter={() => setHovering(true)}
                onMouseLeave={() => setHovering(false)}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={params.value[0].startsWith('data:image/') ? params.value[0] : `${process.env.REACT_APP_BASE_PREFIX}/itemImages/${params.value[0]}`}
                  alt="Image"
                  style={{ height: "60px", borderRadius: "5px" }}
                />
                {hovering && (
                  <IconButton
                    size="large"
                    onClick={() => handleImageDialogOpen(params.row.id)}
                    style={{
                      position: "absolute",
                      top: "4px",
                      right: "4px", // Adjust the positioning as needed
                      background: "rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    <CollectionsOutlined
                      fontSize="large"
                      sx={{
                        color: "#fff",
                      }}
                    />
                  </IconButton>
                )}
              </div>
            ) : (
              <IconButton
                size="large"
                onClick={() => handleImageDialogOpen(params.row.id)}
              >
                <CollectionsOutlined
                  fontSize="large"
                  sx={{
                    color: "#fff",
                  }}
                />
              </IconButton>
            )}
          </Box>
        </Tooltip>
      ),
    },
    {
      field: "Brand",
      headerName: "Brand",
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            height: '100%',
            width: '98%',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TextField
            size="small"
            defaultValue={params.value}
            onChange={(e) => handleFieldChange('Brand', e.target.value, params.row.id)}
            fullWidth
            label="Brand"
            onKeyDown={(e) => {
              if (e.key === ' ' || e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
                e.stopPropagation();
              }
            }}
            placeholder="Brand..."
            color="secondary"
            type="text"
          />
        </Box >
      ),
    },
    {
      field: "sku",
      headerName: "SKU",
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            height: '100%',
            width: '98%',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TextField
            size="small"
            defaultValue={params.value}
            onChange={(e) => handleFieldChange('sku', e.target.value, params.row.id)}
            label="SKU"
            fullWidth
            onKeyDown={(e) => {
              if (e.key === ' ' || e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
                e.stopPropagation();
              }
            }}
            placeholder="SKU..."
            color="secondary"
            type="text"
          />
        </Box >
      ),
    },
    {
      field: "title",
      headerName: "Title",
      flex: 2.5,
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : "Title"}>
          <Box
            sx={{
              height: '100%',
              width: '98%',
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              size="small"
              defaultValue={params.value}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (inputValue.length <= 80) {
                  handleFieldChange('title', inputValue, params.row.id);
                }
              }}
              fullWidth
              label={`Title ${params.value.length}/80`}
              onKeyDown={(e) => {
                if (e.key === ' ' || e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
                  e.stopPropagation();
                }
              }}
              placeholder="Title..."
              color="secondary"
              type="text"
            />
          </Box>
        </Tooltip>
      ),
    },
    {
      field: "supplierPrice",
      headerName: "Supplier Price",
      flex: 0.9,
      renderCell: (params) => (
        <Box
          sx={{
            height: '100%',
            width: '98%',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TextField
            size="small"
            value={params.value}
            type="number"
            fullWidth
            label="Supplier Price"
            placeholder="Price..."
            color="secondary"
            InputProps={{
              readOnly: true,
              min: 0,
              endAdornment: (
                <InputAdornment position="end">
                  <AttachMoneyOutlined />
                </InputAdornment>
              ),
            }}
          />
        </Box >
      )
    },
    {
      field: "suggestedPrice",
      headerName: "Suggested Price",
      flex: 0.9,
      renderCell: (params) => (
        <Box
          sx={{
            height: '100%',
            width: '98%',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TextField
            size="small"
            value={params.value}
            type="number"
            fullWidth
            label="Suggested Price"
            placeholder="Price..."
            color="secondary"
            InputProps={{
              readOnly: true,
              min: 0,
              endAdornment: (
                <InputAdornment position="end">
                  <AttachMoneyOutlined />
                </InputAdornment>
              ),
            }}
          />
        </Box >
      )
    },
    {
      field: "price",
      headerName: "eBay Price",
      flex: 0.9,
      renderCell: (params) => (
        <Box
          sx={{
            height: '100%',
            width: '98%',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TextField
            size="small"
            defaultValue={params.value}
            type="number"
            onChange={(e) => handleFieldChange('price', e.target.value, params.row.id)}
            fullWidth
            onKeyDown={(e) => {
              if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
                e.stopPropagation();
              }
            }}
            label="eBay Price"
            placeholder="Price..."
            color="secondary"
            InputProps={{
              min: 0,
              endAdornment: (
                <InputAdornment position="end">
                  <AttachMoneyOutlined />
                </InputAdornment>
              ),
            }}
          />
        </Box >
      )
    },
    {
      field: "quantity",
      headerName: "Quantity",
      flex: 0.8,
      renderCell: (params) => (
        <Box
          sx={{
            height: '100%',
            width: '98%',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TextField
            size="small"
            defaultValue={params.value}
            onChange={(e) => handleFieldChange('quantity', e.target.value, params.row.id)}
            fullWidth
            onKeyDown={(e) => {
              if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
                e.stopPropagation();
              }
            }}
            label="Quantity"
            type="number"
            placeholder="Quantity..."
            color="secondary"
            InputProps={{
              min: 0,
              endAdornment: (
                <InputAdornment position="end">
                  <NumbersOutlined />
                </InputAdornment>
              ),
            }}
          />
        </Box >
      )
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            width: "100%",
            '& > *': {
              margin: '0 5px',
            },
          }}
        >
          <IconButton
            onClick={() => removeListing(params.row.id)}
          >
            <Delete sx={{
              color: "#fff"
            }} />
          </IconButton>
          <IconButton
            onClick={() => {
              setItemDetailsDialogOpen(true);
              setListingId(params.row.id);
              setListing(listings.find(({ id }) => id === params.row.id));
            }}
          >
            <TuneOutlined sx={{
              color: "#fff"
            }} />
          </IconButton>
        </Box >
      )
    },
  ];

  useEffect(() => {
    // dispatch(fetchEbayCategories(storeId));
    dispatch(getCategory());
    dispatch(getItemSpecifics());
  }, []);

  useEffect(() => {
    setCategories(fetchedCategories);
    setCategoryString(fetchedCategoryString);
    setSubcategories(fetchedSubcategories);
    setLastSubcategory(fetchedLastSubcategory);
    setItemSpecifics(fetchedItemSpecifics);
  }, [
    fetchedCategories,
    fetchedSubcategories,
    fetchedCategoryString,
    fetchedLastSubcategory,
    fetchedItemSpecifics
  ]);

  useEffect(() => {
    if (listingCategoryIds) {
      dispatch(getCategory(listingCategoryIds.join(",")));
    }
  }, [listingCategoryIds]);

  useEffect(() => {
    if (formatUploadStatus === "fulfilled" && formatedUpload?.length > 0) {
      const updateFormatedUpload = formatedUpload.map((item) => {
        const newListing = {
          id: listings.length + item.id,
          images: item.images,
          sku: item.sku,
          title: item.title,
          supplierPrice: item.supplierPrice,
          suggestedPrice: item.suggestedPrice,
          price: item.price,
          quantity: item.quantity,
          description: item.description,
          supplierId: item.supplierId,
          category: item.category
        };

        itemSpecifics.forEach(({ name }) => {
          if (item[name]) {
            newListing[name] = item[name];
          } else {
            newListing[name] = "";
          }
        });

        return newListing;
      });

      setListings([
        ...listings,
        ...updateFormatedUpload,
      ]);
    }
  }, [formatUploadStatus]);

  const handleCategoryDialogOpen = (id, categoryId) => {
    setListingId(id);
    if (categoryId?.length > 0) {
      setListingCategoryId(categoryId.length > 1 ? categoryId.pop() : categoryId);
      setListingCategoryIds(categoryId);
    }
    setCategoryDialogOpen(true);
  };

  const handleCategoryChange = (categoryId) => {
    setListingCategoryIds([...listingCategoryIds, categoryId]);
  };

  const handleCategoryBackChange = () => {
    const updatedCategoryIds = [...listingCategoryIds];
    updatedCategoryIds.pop();
    setListingCategoryIds(updatedCategoryIds);

    if (updatedCategoryIds?.length < 1) {
      setCategoryString("");
    }

    console.log(updatedCategoryIds)
    console.log(updatedCategoryIds?.length < 1)
  };

  const handleCategoryChangeConfirm = () => {
    setListings([
      ...listings.filter(({ id }) => id !== listingId),
      {
        ...listings.find(({ id }) => id === listingId),
        category: {
          id: listingCategoryIds,
          name: categoryString
        }
      }
    ]);

    setListingId("");
    setListingCategoryIds("");
    setCategoryDialogOpen(false);
  };

  const handleImageDialogOpen = (id) => {
    setListingId(id);
    setImages(listings.find(obj => obj.id === id).images);
    setImageDialogOpen(true);
  };

  const handleImageChangeAdd = (event) => {
    const newImages = [...images];

    Array.from(event.target.files).forEach((file) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        newImages.push(e.target.result);
        setImages(newImages);
      };

      reader.readAsDataURL(file);
    });
  };

  const handleImageChangeRemove = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const handleImageChangeConfirm = () => {
    setListings([
      ...listings.filter(({ id }) => id !== listingId),
      {
        ...listings.find(({ id }) => id === listingId),
        images
      }
    ]);

    setListingId("");
    setImages([]);
    setImageDialogOpen(false);
  };

  const handleFieldChange = (type, value, id) => {
    setListings(prevListings => {
      return prevListings.map(item => {
        if (item.id === id) {
          // If the item's id matches the specified id, update the 'type' property
          return {
            ...item,
            [type]: value
          };
        }
        // Otherwise, return the item unchanged
        return item;
      });
    });
  };

  const addListing = () => {
    const newListing = {
      id: listings.length + 1,
      category: {},
      images: [],
      sku: "",
      title: "",
      supplierPrice: 0,
      suggestedPrice: 0,
      price: 0,
      quantity: 0,
    };

    itemSpecifics.forEach(({ name }) => {
      newListing[name] = "";
    });

    setListings([
      ...listings,
      newListing
    ]);
  };

  const removeListing = (index) => {
    setListings([
      ...listings
        .filter(obj => obj.id !== index)
        .map((obj, i) => ({ ...obj, id: i > index ? i : i + 1 }))
    ]);
  };

  const addItemSpecific = () => {
    setItemSpecifics([...itemSpecifics, {
      name: "",
      required: false
    }]);
  };

  const removeItemSpecific = (index) => {
    setItemSpecifics(prevListings => {
      return prevListings.filter((item, itemIndex) => itemIndex !== index);
    });
  };

  const handleItemSpecificFieldChange = (index, name) => {
    setItemSpecifics(prevListings => {
      return prevListings.map((item, itemIndex) => {
        if (itemIndex === index) {
          return {
            ...item,
            name
          };
        }
        return item;
      });
    });
  };

  const handleItemSpecificRequiredChange = (index) => {
    setItemSpecifics(prevListings => {
      return prevListings.map((item, itemIndex) => {
        if (itemIndex === index) {
          return {
            ...item,
            required: !item.required
          };
        }
        return item;
      });
    });
  };

  const handleItemDetailsConfirm = () => {
    setListings(prevListings => {
      return prevListings.map(item => {
        if (item.id === listingId) {
          return listing;
        }
        return item;
      });
    });

    setItemDetailsDialogOpen(false);
    setListingId("");
    setListing({});
  };

  const handleUploadItems = () => {
    dispatch(formatUpload({ storeId, file: uploadFile }));
    setUploadItemsDialogOpen(false);
  };

  const handleCreateListings = () => {
    dispatch(addListings({ storeId, listings, itemSpecifics }));
    setAddListingsDialogOpen(false)
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Create Listings" subtitle="Create Listings Manage For Your Store" />
      <Box
        m="1.5rem 0 0.5rem 0"
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <LoadingButton
          color="secondary"
          variant="contained"
          sx={{
            width: "20%",
            padding: "15px 15px",
            whiteSpace: 'nowrap',
            fontSize: '12px',
          }}
          onClick={() => setAddListingsDialogOpen(true)}
          loading={addListingsStatus === "pending"}
        >
          Create Listing
        </LoadingButton>
      </Box>
      <Box
        height="80vh"
        sx={{
          marginTop: "1rem",
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.primary[500],
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.primary[500],
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          sx={{
            "& .MuiDataGrid-cell": {
              borderBottom: `1px solid ${theme.palette.secondary[200]} !important`,
            },
            "& .MuiDataGrid-cell:focus": {
              outline: `none !important`,
            },
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            "& .MuiDataGrid-checkboxInput": {
              color: `${theme.palette.secondary[400]} !important`,
            },
          }}
          getRowId={(row) => row.id}
          rows={listings || []}
          rowCount={listings.length}
          columns={columns}
          pageSizeOptions={[25, 50, 100]}
          // paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          checkboxSelection
          density="comfortable"
          onRowSelectionModelChange={(newSelection) => {
            setSelectedItems(newSelection);
          }}
          components={{
            Toolbar: () => (
              <GridToolbarContainer>
                <FlexBetween width="100%">
                  <FlexBetween width="40%" mb="20px">
                    <Button
                      variant="outlined"
                      color="secondary"
                      startIcon={<AddOutlined color="secondary" />}
                      onClick={addListing}
                    >
                      Add Listing
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      startIcon={<TuneOutlined color="secondary" />}
                      onClick={() => setItemSpecificsDialogOpen(true)}
                    >
                      Item Specifics
                    </Button>
                    <input
                      accept=".xlsx"
                      id="uplaod-file-xlsx"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={(e) => {
                        setUploadFile(e.target.files[0]);
                        setUploadItemsDialogOpen(true);
                      }}
                    />
                    <label
                      htmlFor="uplaod-file-xlsx"
                      style={{
                        display: 'inline-block',
                      }}
                    >
                      <LoadingButton
                        variant="outlined"
                        color="secondary"
                        component="span"
                        startIcon={<FileUploadOutlined color="secondary" />}
                        loading={formatUploadStatus === "pending"}
                      >
                        Upload Items
                      </LoadingButton>
                    </label>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => setListings([])}
                    >
                      Clear Listings
                    </Button>
                  </FlexBetween>
                </FlexBetween>
              </GridToolbarContainer>
            ),
          }}
          rowSelectionModel={selectedItems}
          disableRowSelectionOnClick
        />
      </Box>
      <Dialog
        open={categoryDialogOpen}
        onClose={() => setCategoryDialogOpen(false)}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <Box
          sx={{
            backgroundColor: theme.palette.primary[400],
          }}
        >
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Box>
                <Typography fontWeight="bold" variant="h4" p="3px" mb="-10px">
                  {
                    listingCategoryIds?.length > 0 ? categoryString : "Select A Main Category"
                  }
                </Typography>
              </Box>
              <Box>
                {
                  (categories && listingCategoryIds.length > 0)
                    ? !lastSubcategory && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center"
                        }}
                      >
                        <FormControl sx={{ marginTop: "30px", width: "80%" }}>
                          <InputLabel
                            color="secondary"
                            id="category-dialog-select-label"
                          >Select Category</InputLabel>
                          <Select
                            color="secondary"
                            labelId="category-dialog-select-label"
                            id="category-dialog-select"
                            label="Select Category"
                            value={listingCategoryId}
                            onChange={(e) => handleCategoryChange(e.target.value)}
                          >
                            <MenuItem value="select">Select</MenuItem>
                            {
                              subcategories.map(({ id, name }) => (
                                <MenuItem value={id}>{name}</MenuItem>
                              ))
                            }
                          </Select>
                        </FormControl>
                        <Fab
                          size="small"
                          color="secondary"
                          aria-label="Back"
                          sx={{
                            marginTop: "25px",
                            marginRight: "25px",
                          }}
                          onClick={handleCategoryBackChange}
                        >
                          <ArrowBackIosNew />
                        </Fab>
                      </Box>
                    )
                    : (
                      <FormControl sx={{ marginTop: "30px", width: "100%" }}>
                        <InputLabel
                          color="secondary"
                          id="category-dialog-select-label"
                        >Select Category</InputLabel>
                        <Select
                          color="secondary"
                          labelId="category-dialog-select-label"
                          id="category-dialog-select"
                          label="Select Category"
                          value={listingCategoryId}
                          onChange={(e) => handleCategoryChange(e.target.value)}
                        >
                          <MenuItem value="select">Select</MenuItem>
                          {
                            categories.map(({ id, name }) => (
                              <MenuItem value={id}>{name}</MenuItem>
                            ))
                          }
                        </Select>
                      </FormControl>
                    )
                }
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCategoryDialogOpen(false)} color="secondary">
              Cancel
            </Button>
            <div style={{ flex: 1 }}></div>
            <Button onClick={handleCategoryChangeConfirm} color="secondary">
              Select
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Dialog
        open={itemSpecificsDialogOpen}
        onClose={() => setItemSpecificsDialogOpen(false)}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <Box
          sx={{
            backgroundColor: theme.palette.primary[400],
          }}
        >
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Box>
                <Typography fontWeight="bold" variant="h4" p="3px">
                  Manage Item Specifics
                </Typography>
              </Box>
              <Box
                sx={{
                  marginTop: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "space-between",
                  gap: "1rem"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "space-between",
                    gap: "10px"
                  }}
                >
                  {
                    itemSpecifics?.map(({ name, required }, index) => (
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <TextField
                          size="small"
                          label={name}
                          value={name}
                          onChange={(e) => handleItemSpecificFieldChange(index, e.target.value)}
                          sx={{
                            width: "70%"
                          }}
                          placeholder="Name..."
                          color="secondary"
                          type="text"
                        />
                        <Fab
                          size="small"
                          color="secondary"
                          aria-label="required"
                          onClick={() => handleItemSpecificRequiredChange(index)}
                        >
                          {
                            required
                              ? <Lock />
                              : <LockOpenOutlined />
                          }
                        </Fab>
                        <Fab
                          size="small"
                          color="secondary"
                          aria-label="remove"
                          onClick={() => removeItemSpecific(index)}
                        >
                          <Delete />
                        </Fab>
                      </Box>
                    ))
                  }
                </Box>
                <Box>
                  <Fab
                    size="small"
                    color="secondary"
                    aria-label="add"
                    onClick={addItemSpecific}
                  >
                    <AddOutlined />
                  </Fab>
                </Box>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setItemSpecificsDialogOpen(false);
                dispatch(getItemSpecifics());
              }}
              color="secondary"
            >
              Cancel
            </Button>
            <div style={{ flex: 1 }}></div>
            <Button
              onClick={() => {
                dispatch(updateItemSpecifics(itemSpecifics));
                setItemSpecificsDialogOpen(false);
              }}
              color="secondary"
            >
              Done
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Dialog
        open={imageDialogOpen}
        onClose={() => setImageDialogOpen(false)}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <Box
          sx={{
            backgroundColor: theme.palette.primary[400],
          }}
        >
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography fontWeight="bold" variant="h4" p="3px" mb="-10px">
                  Manage Listing Images
                </Typography>
                <input
                  accept="image/*"
                  multiple
                  id="import-images"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={handleImageChangeAdd}
                />
                <label
                  htmlFor="import-images"
                  style={{
                    display: 'inline-block',
                    margin: "1rem 0 0 0",
                    width: "25%",
                  }}>
                  <Button
                    color="secondary"
                    variant="contained"
                    component="span"
                    sx={{
                      width: "100%",
                      padding: "10px 10px",
                      alignSelf: "flex-end",
                      whiteSpace: 'nowrap',
                      fontSize: '11px',
                    }}
                  >
                    Add Images
                  </Button>
                </label>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ImageList sx={{ width: "90%", maxHeight: 325, overflow: "auto" }} cols={3}>
                  {images.map((image, index) => (
                    <ImageListItem key={index} sx={{ height: 165 }}>
                      <img
                        src={image.startsWith('data:image/') ? image : `${process.env.REACT_APP_BASE_PREFIX}/itemImages/${image}`}
                        alt="Item Image"
                        loading="lazy"
                        style={{
                          borderRadius: '5px',
                        }}
                      />
                      <Fab
                        size="small"
                        color="secondary"
                        aria-label="delete"
                        sx={{
                          position: 'absolute',
                          bottom: '5px',
                          right: '5px',
                        }}
                        onClick={() => handleImageChangeRemove(index)}
                      >
                        <Delete />
                      </Fab>
                    </ImageListItem>
                  ))}
                </ImageList>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setImageDialogOpen(false)} color="secondary">
              Cancel
            </Button>
            <div style={{ flex: 1 }}></div>
            <Button onClick={handleImageChangeConfirm} color="secondary">
              Select
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Dialog
        open={itemDetailsDialogOpen}
        onClose={() => setItemDetailsDialogOpen(false)}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth="lg"
        fullWidth
      >
        <Box
          sx={{
            backgroundColor: theme.palette.primary[400],
          }}
        >
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Box>
                <Typography fontWeight="bold" variant="h4" p="3px">
                  Manage Listing Details
                </Typography>
              </Box>
              <Box
                sx={{
                  marginTop: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "space-between",
                  gap: "1rem"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "space-between",
                    gap: "20px",
                  }}
                >
                  <TextField
                    size="small"
                    label="Description"
                    multiline
                    rows={8}
                    value={listing?.description || ''}
                    onChange={(e) => setListing({ ...listing, description: e.target.value })}
                    fullWidth
                    placeholder="Description..."
                    color="secondary"
                    type="text"
                  />
                  {
                    itemSpecifics?.map(({ name, required }, index) => (
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "space-between",
                          gap: "10px"
                        }}
                      >
                        <TextField
                          size="small"
                          value={`${name}${required ? ` ( Required )` : ""}`}
                          inputProps={{
                            readOnly: true
                          }}
                          sx={{
                            width: "20%"
                          }}
                          color="secondary"
                          type="text"
                        />
                        <TextField
                          label={name}
                          value={listing[name]}
                          onChange={(e) => setListing({ ...listing, [name]: e.target.value })}
                          sx={{
                            width: "60%"
                          }}
                          color="secondary"
                          type="text"
                        />
                      </Box>
                    ))
                  }
                </Box>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              setItemDetailsDialogOpen(false);
              setListingId("");
              setListing({});
            }} color="secondary">
              Cancel
            </Button>
            <div style={{ flex: 1 }}></div>
            <Button
              onClick={handleItemDetailsConfirm}
              color="secondary"
            >
              Done
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <DialogBox
        open={uploadItemsDialogOpen}
        onClose={() => setUploadItemsDialogOpen(false)}
        onConfirm={handleUploadItems}
        titleText="Upload Confirmation"
        messageText="Are you sure you wish to upload these items?"
        confirmText="Confirm Upload"
      />
      <DialogBox
        open={addListingsDialogOpen}
        onClose={() => setAddListingsDialogOpen(false)}
        onConfirm={handleCreateListings}
        titleText="Create Listings Confirmation"
        messageText="Are you sure you wish to create these listings?"
        confirmText="Confirm Create"
      />
    </Box>
  );
};

export default CreateListings;