import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
} from "@mui/material";

import Header from "components/Header";
import DialogBox from "components/DialogBox";

import ProfileForm from "components/ProfileForm";

import { useDispatch, useSelector } from "react-redux";
import { createUser, clearStatus } from "features/staff/staffSlice";

const CreateUser = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const { createUserStatus } = useSelector((state) => state.staff);

  const [formInfo, setFormInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  useEffect(() => {
    dispatch(clearStatus());
  }, []);

  useEffect(() => {
    if (createUserStatus === 'fulfilled') {
      navigate('/users');
    }
  }, [createUserStatus]);

  const handleSubmit = (formInfo) => {
    setFormInfo(formInfo);
    setConfirmDialogOpen(true);
  };

  const handleCreateUser = () => {
    dispatch(createUser(formInfo));
    setConfirmDialogOpen(false);
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Create User" subtitle="Create A New User Profile" />
      <Box m="3rem 0">
        <ProfileForm
          profileInfo={formInfo}
          handleSubmit={handleSubmit}
          saveButtonText="Create User"
          passwordRequired={true}
        />
      </Box>
      <DialogBox
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={handleCreateUser}
        titleText="Create User Confirmation"
        messageText="Are you sure you want to create this new user?"
        confirmText="Create User"
      />
    </Box>
  );
};

export default CreateUser;