import api from "state/api";
import { getConfigWithBearerToken } from "state/api";

export const createUserApi = async (credentials) => {
  try {
    const response = await api.post(
      "user/staff/create-user",
      credentials,
      getConfigWithBearerToken()
    );
    return response.data;
  } catch (error) {
    console.error("Error createUserApi:", error.message);
  }
};

export const getUsersApi = async (page, pageSize) => {
  try {
    const response = await api.get(`user/staff/users?page=${page}&pageSize=${pageSize}`, getConfigWithBearerToken());
    return response.data;
  } catch (error) {
    console.error("Error getUsersApi:", error.message);
  }
};