import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import {
  Box,
  useTheme,
  Button,
  FormControl,
  Switch,
  InputLabel,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import {
  OpenInNewOutlined,
  Inventory2Outlined,
  EditOutlined,
  AddShoppingCartOutlined
} from "@mui/icons-material";

import Header from "components/Header";

import { useDispatch, useSelector } from "react-redux";
import {
  getStores,
  updateStoreSyncSwitch,
  resetStoreInfo
} from "features/stores/storesSlice";
import {
  updateAuthorizatioCode
} from "features/marketplaces/marketplacesSlice";

const Stores = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { totalStoresResults, stores, storesStatus, storeIdPairs } = useSelector((state) => state.stores);

  const [codeDialogOpen, setCodeDialogOpen] = useState(false);
  const [authCodeStore, setAuthCodeStore] = useState("");

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);

  const [rowSyncSwitches, setRowSyncSwitches] = useState({});

  useEffect(() => {
    dispatch(getStores({ type: 'id-pairs' }));
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.get('code')) {
      setCodeDialogOpen(true);
    }
  }, []);

  useEffect(() => {
    dispatch(resetStoreInfo());
    dispatch(getStores({ type: "list", page: page + 1, pageSize }));
  }, [page, pageSize]);

  useEffect(() => {
    // Initialize rowSyncSwitches with the initial values from the fetched data
    const initialRowSyncSwitches = {};
    stores.forEach((store) => {
      initialRowSyncSwitches[store._id] = store.syncSwitch;
    });
    setRowSyncSwitches(initialRowSyncSwitches);
  }, [stores]);

  const handleSyncSwitchChange = (syncSwitch, storeId) => {
    setRowSyncSwitches((prevRowSyncSwitches) => ({
      ...prevRowSyncSwitches,
      [storeId]: syncSwitch,
    }));

    dispatch(updateStoreSyncSwitch({ storeId, syncSwitch }));
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 0.6 },
    { field: "storeName", headerName: "Store", flex: 2 },
    { field: "storeEmail", headerName: "Email", flex: 1.5 },
    { field: "storeSuppliers", headerName: "Suppliers", flex: 1.5 },
    {
      field: "syncSwitch",
      headerName: "Sync Store",
      flex: 1,
      renderCell: (params) => (
        <Box>
          <FormControl
            component="fieldset"
          >
            <Switch
              color="secondary"
              checked={rowSyncSwitches[params.row._id]}
              onChange={() => handleSyncSwitchChange(!rowSyncSwitches[params.row._id], params.row._id)}
            />
          </FormControl>
        </Box>
      ),
    },
    {
      field: "refreshTokenValid",
      headerName: "Refresh Token Valid",
      flex: 1,
      renderCell: (params) => (
        < div
          style={{
            width: "80%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "5px",
            padding: "6px",
            fontWeight: "semibold",
            color: "white",
            backgroundColor: params.value ? "#148F59" : "#BB2722",
          }}
        >
          {params.value ? "Valid" : "In Valid"}
        </div >
      )
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1.5,
      renderCell: (params) => (
        <Box
          sx={{
            width: "100%",
            '& > *': {
              margin: '0 8px',
            },
          }}
        >
          <Link to={`/stores/${params.row._id}`}>
            <OpenInNewOutlined color="secondary" />
          </Link>
          <Link to={`/stores/${params.row._id}/sourcing setup`}>
            <Inventory2Outlined color="secondary" />
          </Link>
          <Link to={`/stores/${params.row._id}/edit`}>
            <EditOutlined color="secondary" />
          </Link>
          <Link to={`/stores/${params.row._id}/create listings`}>
            <AddShoppingCartOutlined color="secondary" />
          </Link>
        </Box>
      ),
    },
  ];

  const handleCode = () => {
    const queryParams = new URLSearchParams(location.search);
    const code = encodeURIComponent(queryParams.get('code'));
    if (code) {
      dispatch(updateAuthorizatioCode({ storeId: authCodeStore, code }));
    }
    setCodeDialogOpen(false);

    navigate('/stores');
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Stores" subtitle="Managing The ebay Stores" />
      <Box my="15px">
        <Button
          color="secondary"
          variant="contained"
          sx={{
            width: "20%",
            padding: "10px"
          }}
          onClick={() => navigate('/stores/create')}
        >
          Add Store
        </Button>
      </Box>
      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.primary[500],
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.primary[500],
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          sx={{
            "& .MuiDataGrid-cell": {
              borderBottom: `1px solid ${theme.palette.secondary[200]}`
            },
          }}
          className="custom-data-grid"
          loading={storesStatus === "pending"}
          rows={stores || []}
          rowCount={totalStoresResults}
          columns={columns}
          rowsPerPageOptions={[20, 50, 100]}
          pagination
          paginationMode="server"
          page={page}
          pageSize={pageSize}
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        />
      </Box>
      <Dialog
        open={codeDialogOpen}
        onClose={() => setCodeDialogOpen(false)}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Code Store Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Choose the store that you generated the code for.
          </DialogContentText>
          <FormControl sx={{ m: 1, width: "100%" }}>
            <InputLabel
              id="select-storeId-range-label"
              color="secondary"
            >Store</InputLabel>
            <Select
              labelId="select-store-range-label"
              value={authCodeStore}
              label="store"
              onChange={(e) => setAuthCodeStore(e.target.value)}
              color="secondary"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {storeIdPairs.map((option) => (
                <MenuItem key={option._id} value={option._id}>
                  {option.storeName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Box>
              <Button
                onClick={() => setCodeDialogOpen(false)}
                color="secondary"
              >Cancel
              </Button>
              <Button
                onClick={handleCode}
                color="secondary"
              >Confirm Store
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Stores;