import React, { useState, useEffect } from "react";

import {
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Typography,
  TextField,
  Divider,
  LinearProgress,
  Checkbox,
  Switch,
  useTheme
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import socket from "app/socket";

import Header from "components/Header";
import SubHeader from "components/SubHeader";
import DialogBox from "components/DialogBox";

import { useDispatch, useSelector } from "react-redux";

import {
  getSuppliers,
  getSupplierScraperInfo,
  updateSupplierScraperInfo
} from "features/suppliers/suppliersSlice";

const ScraperConfig = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    supplierIdPairs,
    supplierScraperInfo,
    scraperUpdateStatus
  } = useSelector((state) => state.suppliers);

  const [scraper, setScraper] = useState("");
  const [supplierSiteCredentialsChanged, setSupplierSiteCredentialsChanged] = useState(false);
  const [supplierSiteCredentials, setSupplierSiteCredentials] = useState({});
  const [supplierScraperConfig, setSupplierScraperConfig] = useState({});

  const [scraperProgress, setScraperProgress] = useState(0);
  const [refreshBrandsProgress, setRefreshBrandsProgress] = useState("idle");

  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [scrapeDialogOpen, setScrapeDialogOpen] = useState(false);
  const [refreshBrandsDialogOpen, setRefreshBrandsDialogOpen] = useState(false);
  
  useEffect(() => {
    dispatch(getSuppliers({ type: 'id-pairs' }));
  }, []);

  useEffect(() => {
    if (!scraper && supplierIdPairs.length > 0) {
      setScraper(supplierIdPairs[0]._id);
    }
    if (scraper) {
      dispatch(getSupplierScraperInfo(scraper));
    }
  }, [supplierIdPairs]);

  useEffect(() => {
    if (scraper) {
      dispatch(getSupplierScraperInfo(scraper));
    }
  }, [scraper]);

  useEffect(() => {
    if (scraper && scraperUpdateStatus !== "pending") {
      dispatch(getSupplierScraperInfo(scraper));
    }
  }, [scraperUpdateStatus]);

  useEffect(() => {
    if (supplierScraperInfo) {
      setSupplierSiteCredentials(supplierScraperInfo.supplierSiteCredentials);
      setSupplierScraperConfig(supplierScraperInfo.supplierScraperConfig);
    }
  }, [supplierScraperInfo]);

  useEffect(() => {
    if (JSON.stringify(supplierScraperInfo.supplierSiteCredentials) !== JSON.stringify(supplierSiteCredentials)) {
      setSupplierSiteCredentialsChanged(true);
    } else {
      setSupplierSiteCredentialsChanged(false);
    }
  }, [supplierSiteCredentials]);

  useEffect(() => {
    socket.on("scraperProgress", (data) => {
      setScraperProgress(data);
    });

    socket.on("refreshBrandsProgress", (data) => {
      setRefreshBrandsProgress(data);
    });

    return () => {
      socket.off("scraperProgress");
      socket.off("refreshBrandsProgress");
    };
  }, []);

  return (
    <Box m="1.5rem 2.5rem" pb="2rem">
      <Box
        sx={{
          display: "flex",
          width: "75%",
          justifyContent: "space-between"
        }}
      >
        <Header title={supplierScraperInfo.supplierNonCamelCase ? `${supplierScraperInfo.supplierNonCamelCase}, Scraper` : "Scraper Config"} subtitle="Edit And Manage Your Supplier Scrapers" />
        <FormControl sx={{ m: 1, minWidth: 235 }}>
          <InputLabel
            id="select-scraper-range-label"
            color="secondary"
          >Scraper</InputLabel>
          <Select
            labelId="select-scraper-range-label"
            value={scraper}
            label="Scraper"
            onChange={(e) => setScraper(e.target.value)}
            color="secondary"
            sx={{
              '&:not(:focus) .MuiInputLabel-root': {
                color: theme.palette.secondary[200],
              },
              '& .MuiInput-underline:after': {
                borderBottomColor: theme.palette.secondary[200],
              },
            }}
          >
            {supplierIdPairs.map((option) => (
              <MenuItem key={option._id} value={option._id}>
                {option.supplierNonCamelCase}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box
        mt="3rem"
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "75%",
          gap: "2rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <SubHeader title="Scraper Control" />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "60%",
              borderRadius: "10px",
              bgcolor: theme.palette.background.alt,
              padding: "2rem",
              gap: "2rem"
            }}
          >
            <Box>
              <Typography variant="h6" p="3px" mb="20px">Supplier Site Credentials</Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <TextField
                  label="Customer"
                  placeholder="Email / Username..."
                  value={supplierSiteCredentials?.customer || ""}
                  onChange={(e) => setSupplierSiteCredentials({
                    ...supplierSiteCredentials,
                    customer: e.target.value
                  })}
                  sx={{ width: "45%" }}
                  color="secondary"
                />
                <TextField
                  label="Password"
                  placeholder="Password..."
                  value={supplierSiteCredentials?.password || ""}
                  onChange={(e) => setSupplierSiteCredentials({
                    ...supplierSiteCredentials,
                    password: e.target.value
                  })}
                  sx={{ width: "45%" }}
                  color="secondary"
                />
              </Box>
              {
                supplierSiteCredentialsChanged &&
                <LoadingButton
                  loading={scraperUpdateStatus === "pending"}
                  color="secondary"
                  variant="contained"
                  sx={{
                    width: "45%",
                    alignSelf: "flex-end",
                    padding: "18px",
                    marginTop: "20px",
                  }}
                  onClick={() => dispatch(updateSupplierScraperInfo({ supplierId: scraper, supplierSiteCredentials }))}
                >
                  Save
                </LoadingButton>
              }
            </Box>
            <Divider middle />
            <Box mb="40px" >
              <Typography variant="h6" p="3px" mb="20px">Supplier Control</Typography>
              {scraperProgress ?
                <Box
                  sx={{
                    width: "45%",
                    height: "100%",
                    position: "relative",
                  }}
                >
                  <LinearProgress
                    sx={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "5px",
                    }}
                    color="secondary"
                    variant="determinate"
                    value={scraperProgress}
                  />
                  <Typography
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "white",
                    }}
                  >
                    {`${scraperProgress}%`}
                  </Typography>
                </Box>
                : <LoadingButton
                  color="secondary"
                  variant="contained"
                  sx={{
                    width: "45%",
                    alignSelf: "flex-end",
                    padding: "17px",
                    marginBottom: "-50px",
                    marginTop: "-20px"
                  }}
                  onClick={() => setScrapeDialogOpen(true)}
                >
                  Start Scraper
                </LoadingButton>
              }
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <SubHeader title="Scraper Settings" />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "60%",
              borderRadius: "10px",
              bgcolor: theme.palette.background.alt,
              padding: "2rem",
              gap: "2rem"
            }}
          >
            <Box>
              <Typography variant="h6" p="3px" mb="20px">Scraper Brands</Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <FormControl sx={{ width: "45%" }}>
                  <InputLabel
                    id="select-scraper-range-label"
                    color="secondary"
                  >Select Brands</InputLabel>
                  <Select
                    labelId="select-scraper-range-label"
                    value={supplierScraperConfig?.selectedBrands || ""}
                    multiple
                    label="Select Brands"
                    onChange={(e) => setSupplierScraperConfig({
                      ...supplierScraperConfig,
                      selectedBrands: e.target.value
                    })}
                    renderValue={(selected) => `Brands Selected ${selected.length}`}
                    color="secondary"
                  >
                    {supplierScraperConfig?.supplierBrands &&
                      supplierScraperConfig.supplierBrands.map((option) => (
                        <MenuItem key={option.url} value={option.url}>
                          <Checkbox
                            checked={supplierScraperConfig.selectedBrands.indexOf(option.url) > -1}
                            color="secondary"
                          />
                          <a
                            href={option.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: 'none', color: 'white' }}
                          >
                            {option.brand}
                          </a>
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <LoadingButton
                  loading={refreshBrandsProgress === "pending"}
                  color="secondary"
                  variant="contained"
                  sx={{
                    width: "45%",
                    alignSelf: "flex-end",
                    padding: "17px",
                  }}
                  onClick={() => setRefreshBrandsDialogOpen(true)}
                >
                  Refresh Brands
                </LoadingButton>
              </Box>
            </Box>
            <TextField
              label="Selected Brands"
              multiline
              value={
                supplierScraperConfig?.selectedBrands?.length > 0
                  ? supplierScraperConfig.supplierBrands
                    .filter(({ url }) => supplierScraperConfig.selectedBrands.includes(url))
                    .map(obj => obj.brand)
                    .join(", ")
                  : ""
              }
              InputProps={{
                readOnly: true,
              }}
              rows={2}
              fullWidth
              color="secondary"
              placeholder="Selected Brands To Scrape..."
            />
            <Divider middle />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%"
              }}
            >
              <Typography variant="h6" p="3px">Scraper Delay Feature</Typography>
              {
                typeof supplierScraperConfig?.delaySelected === 'boolean' &&
                <FormControl
                  component="fieldset"
                >
                  <Switch
                    color="secondary"
                    checked={supplierScraperConfig.delaySelected}
                    onChange={() => setSupplierScraperConfig({
                      ...supplierScraperConfig,
                      delaySelected: !supplierScraperConfig.delaySelected
                    })}
                  />
                </FormControl>
              }
            </Box>
            {
              (typeof supplierScraperConfig?.delaySelected === 'boolean' &&
                supplierScraperConfig?.delaySelected) &&
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <FormControl sx={{ width: "45%" }}>
                  <InputLabel
                    id="select-scraper-range-label"
                    color="secondary"
                  >Delay On</InputLabel>
                  <Select
                    labelId="select-scraper-range-label"
                    value={supplierScraperConfig?.delayOn || ""}
                    label="Delay On"
                    onChange={(e) => setSupplierScraperConfig({
                      ...supplierScraperConfig,
                      delayOn: e.target.value
                    })}
                    color="secondary"
                    sx={{
                      '&:not(:focus) .MuiInputLabel-root': {
                        color: theme.palette.secondary[200],
                      },
                      '& .MuiInput-underline:after': {
                        borderBottomColor: theme.palette.secondary[200],
                      },
                    }}
                  >
                    <MenuItem key="page" value="page">
                      page
                    </MenuItem>
                    <MenuItem key="item" value="item">
                      item
                    </MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  label="Delay Amount (Seconds)"
                  type="number"
                  inputProps={{ min: 0 }}
                  value={supplierScraperConfig?.delayAmount || "0"}
                  onChange={(e) => setSupplierScraperConfig({
                    ...supplierScraperConfig,
                    delayAmount: e.target.value
                  })}
                  sx={{
                    width: "45%"
                  }}
                  color="secondary"
                  placeholder="Delay Amount..."
                />
              </Box>}
          </Box>
        </Box>
        <LoadingButton
          color="secondary"
          variant="contained"
          sx={{
            width: "20%",
            padding: "10px",
            alignSelf: "flex-end"
          }}
          onClick={() => setSaveDialogOpen(true)}
          loading={scraperUpdateStatus === "pending"}
        >
          Save
        </LoadingButton>
      </Box>
      <DialogBox
        open={saveDialogOpen}
        onClose={() => setSaveDialogOpen(false)}
        onConfirm={() => {
          dispatch(updateSupplierScraperInfo({ supplierId: scraper, supplierScraperConfig }));
          setSaveDialogOpen(false);
        }}
        titleText="Save Confirmation"
        messageText="Are you sure you want to save this change?"
        confirmText="Save"
      />
      <DialogBox
        open={scrapeDialogOpen}
        onClose={() => setScrapeDialogOpen(false)}
        onConfirm={() => {
          socket.emit('scrape', { supplierId: scraper, refreshBrands: false });
          setScrapeDialogOpen(false);
        }}
        titleText="Scrape Confirmation"
        messageText="Are you sure you want to run the scraper?"
        confirmText="Confirm"
      />
      <DialogBox
        open={refreshBrandsDialogOpen}
        onClose={() => setRefreshBrandsDialogOpen(false)}
        onConfirm={() => {
          socket.emit('scrape', { supplierId: scraper, refreshBrands: true });
          setRefreshBrandsDialogOpen(false);
        }}
        titleText="Refresh Brands Confirmation"
        messageText="Are you sure you want to refresh the brands?"
        confirmText="Confirm"
      />
    </Box >
  );
};

export default ScraperConfig;