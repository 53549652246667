import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getSuppliersApi,
  getSupplierInfoApi,
  getSupplierScraperInfoApi,
  fixedShippingFormatApi,
  fixedShippingUploadApi,
  updateShippingWeightsApi,
  updateSupplierInfoApi,
  updateSupplierScraperInfoApi,
  getScrapedInventoryApi,
  deleteScrapedInventoryApi,
  getScrapedItemApi,
  getScrapedInventoryXlsxApi
} from "./suppliersApi";
import { notifyMsg } from "state/api";

export const getSuppliers = createAsyncThunk("suppliers/getSuppliers", async ({ type, page, pageSize }) => {
  const response = await getSuppliersApi(type, page, pageSize);
  return response;
});

export const getScrapedInventory = createAsyncThunk("suppliers/getScrapedInventory", async ({ page, pageSize, search, filters }) => {
  const response = await getScrapedInventoryApi(page, pageSize, search, filters);
  return response;
});

export const getScrapedInventoryXlsx = createAsyncThunk("suppliers/getScrapedInventoryXlsx", async ({ search, filters, selectedItems }) => {
  const response = await getScrapedInventoryXlsxApi(search, filters, selectedItems);
  return response;
});

export const getScrapedItem = createAsyncThunk("suppliers/getScrapedItem", async (scrapedItemId) => {
  const response = await getScrapedItemApi(scrapedItemId);
  return response;
});

export const deleteScrapedInventory = createAsyncThunk("listings/deleteScrapedInventory", async ({ type, data }) => {
  const response = await deleteScrapedInventoryApi(type, data);
  notifyMsg(response);
  return response;
});


export const getSupplierInfo = createAsyncThunk("suppliers/getSupplierInfo", async (supplierId) => {
  const response = await getSupplierInfoApi(supplierId);
  return response;
});

export const getSupplierScraperInfo = createAsyncThunk("suppliers/getSupplierScraperInfo", async (supplierId) => {
  const response = await getSupplierScraperInfoApi(supplierId);
  return response;
});

export const updateSupplierInfo = createAsyncThunk("suppliers/updateSupplierInfo", async ({ supplierId, skuPrefix }) => {
  const response = await updateSupplierInfoApi(supplierId, skuPrefix);
  notifyMsg(response);
  return response;
});

export const updateSupplierScraperInfo = createAsyncThunk("suppliers/updateSupplierScraperInfo", async ({ supplierId, supplierScraperConfig, supplierSiteCredentials }) => {
  const response = await updateSupplierScraperInfoApi(supplierId, supplierScraperConfig, supplierSiteCredentials);
  notifyMsg(response);
  return response;
});

export const fixedShippingFormat = createAsyncThunk("suppliers/fixedShippingFormat", async () => {
  const response = await fixedShippingFormatApi();
  return response;
});

export const fixedShippingUpload = createAsyncThunk("suppliers/fixedShippingUpload", async ({ supplierId, file }) => {
  const response = await fixedShippingUploadApi(supplierId, file);
  notifyMsg(response);
  return response;
});

export const updateShippingWeights = createAsyncThunk("suppliers/updateShippingWeights", async ({ supplierId, shippingWeights }) => {
  const response = await updateShippingWeightsApi(supplierId, shippingWeights);
  notifyMsg(response);
  return response;
});

const initialState = {
  supplierIdPairs: [],
  // suppliers
  totalSuppliers: 0,
  suppliers: [],
  suppliersStatus: "",
  // supplier edit
  supplierInfo: {
    weightShippingCost: []
  },
  updateStatus: "",
  inventoryFormatStatus: "",
  shippingFormatStatus: "",
  // inventory timeline
  totalSupplierInventoryResults: 0,
  supplierUploadedInventories: [],
  inventoriesStatus: "",
  deletedInventoryStatus: "",
  sourcingSetupStatus: "",
  // scraper config
  scraperUpdateStatus: "",
  supplierScraperInfo: {},
  // scraped items
  itemsExportXlsxStatus: "",
  itemsDeleteStatus: "",
  items: [],
  brands: [],
  totalResults: 0,
  totalPages: 0,
  filters: {
    supplier: '',
    brand: '',
    price: {
      minimum: '',
      maximum: ''
    },
    quantity: {
      minimum: '',
      maximum: ''
    }
  },
  // scraped item edit 
  scrapedItemInfo: {}
};

const suppliersSlice = createSlice({
  name: "suppliersSlice",
  initialState,
  reducers: {
    resetSupplierInfo: (state) => {
      state.supplierInfo = {
        weightShippingCost: []
      };
    },
    updateFilter: (state, { payload }) => {
      const { type, value } = payload;
      state.filters[type] = value;
    },
    updateRangeFilter: (state, { payload }) => {
      const { type, value } = payload;
      state.filters[type].minimum = value.split('-')[0];
      state.filters[type].maximum = value.split('-')[1];
    }
  },
  extraReducers: (builder) => {
    builder
      // getSuppliers
      .addCase(getSuppliers.pending, (state) => {
        state.suppliersStatus = "pending";
      })
      .addCase(getSuppliers.fulfilled, (state, { payload }) => {
        if (payload) {
          if (payload.data.type === "id-pairs") {
            state.supplierIdPairs = payload.data.suppliers;
          } else {
            state.totalSuppliers = payload.data.totalSuppliers;
            state.suppliers = payload.data.suppliers;
            state.suppliersStatus = "fulfilled";
          }
        }
      })
      // getScrapedInventory
      .addCase(getScrapedInventory.pending, (state) => {
        state.itemsStatus = "pending";
      })
      .addCase(getScrapedInventory.fulfilled, (state, { payload }) => {
        state.itemsStatus = "fulfilled";
        state.brands = payload.data.brands;
        state.items = payload.data.items;
        state.totalResults = payload.data.totalResults;
        state.totalPages = payload.data.totalPages;
      })
      // getScrapedItem
      .addCase(getScrapedItem.fulfilled, (state, { payload }) => {
        state.scrapedItemInfo = payload.data.scrapedItem;
      })
      // getScrapedInventoryXlsx
      .addCase(getScrapedInventoryXlsx.pending, (state) => {
        state.itemsExportXlsxStatus = "pending";
      })
      .addCase(getScrapedInventoryXlsx.fulfilled, (state) => {
        state.itemsExportXlsxStatus = "fulfilled";
      })
      .addCase(getScrapedInventoryXlsx.rejected, (state) => {
        state.itemsExportXlsxStatus = "rejected";
      })
      // deleteScrapedInventory
      .addCase(deleteScrapedInventory.pending, (state) => {
        state.itemsDeleteStatus = "pending";
      })
      .addCase(deleteScrapedInventory.fulfilled, (state) => {
        state.itemsDeleteStatus = "fulfilled";
      })
      .addCase(deleteScrapedInventory.rejected, (state) => {
        state.itemsDeleteStatus = "rejected";
      })
      // getSupplierInfo
      .addCase(getSupplierInfo.fulfilled, (state, { payload }) => {
        state.supplierInfo = payload.data;
      })
      // getSupplierScraperInfo
      .addCase(getSupplierScraperInfo.fulfilled, (state, { payload }) => {
        state.supplierScraperInfo = payload.data;
      })
      // updateSupplierScraperInfo
      .addCase(updateSupplierScraperInfo.pending, (state) => {
        state.scraperUpdateStatus = "pending";
      })
      .addCase(updateSupplierScraperInfo.fulfilled, (state) => {
        state.scraperUpdateStatus = "fulfilled";
      })
      .addCase(updateSupplierScraperInfo.rejected, (state) => {
        state.scraperUpdateStatus = "rejected";
      })
      // updateSupplierInfo
      .addCase(updateSupplierInfo.pending, (state) => {
        state.updateStatus = "pending";
      })
      .addCase(updateSupplierInfo.fulfilled, (state) => {
        state.updateStatus = "fulfilled";
      })
      .addCase(updateSupplierInfo.rejected, (state) => {
        state.updateStatus = "rejected";
      })
      // fixedShippingFormat
      .addCase(fixedShippingFormat.pending, (state) => {
        state.shippingFormatStatus = "pending";
      })
      .addCase(fixedShippingFormat.fulfilled, (state) => {
        state.shippingFormatStatus = "fulfilled";
      })
      .addCase(fixedShippingFormat.rejected, (state) => {
        state.shippingFormatStatus = "rejected";
      })
      // fixedShippingUpload
      .addCase(fixedShippingUpload.pending, (state) => {
        state.updateStatus = "pending";
      })
      .addCase(fixedShippingUpload.fulfilled, (state, { payload }) => {
        state.updateStatus = "fulfilled";
      })
      .addCase(fixedShippingUpload.rejected, (state) => {
        state.updateStatus = "rejected";
      })
      // updateShippingWeights
      .addCase(updateShippingWeights.pending, (state) => {
        state.updateStatus = "pending";
      })
      .addCase(updateShippingWeights.fulfilled, (state, { payload }) => {
        state.updateStatus = "fulfilled";
      })
      .addCase(updateShippingWeights.rejected, (state) => {
        state.updateStatus = "rejected";
      })
  },
});

export const { resetSupplierInfo, updateFilter, updateRangeFilter } = suppliersSlice.actions;
export default suppliersSlice.reducer;