import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  TextField,
  Button,
  useTheme,
  Typography,
  FormControl,
  FormControlLabel,
  Switch,
  Divider,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

import Header from "components/Header";
import SubHeader from "components/SubHeader";
import DialogBox from "components/DialogBox";

import { useDispatch, useSelector } from "react-redux";
import {
  getListing,
  updateListing,
  deleteListing
} from "features/listings/listingsSlice";

const StoreEdit = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    listingInfo,
    updateStatus,
    deleteStatus
  } = useSelector((state) => state.listings);

  const { listingId } = useParams();

  const [ebayTitle, setEbayTitle] = useState("");
  const [startPrice, setStartPrice] = useState("");
  const [startPriceFixed, setStartPriceFixed] = useState(false);
  const [quantity, setQuantity] = useState(0);

  const [shippingCost, setShippingCost] = useState("");
  const [freeShipping, setFreeShipping] = useState("");

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);

  useEffect(() => {
    dispatch(getListing(listingId));
  }, [])

  useEffect(() => {
    if (listingInfo) {
      setEbayTitle(listingInfo?.title || "");
      setStartPrice(listingInfo?.startPrice?.value || "");
      setStartPriceFixed(listingInfo?.startPrice?.immutability || false);
      setQuantity(listingInfo?.quantity || "");

      setShippingCost(listingInfo?.shippingDetails?.serviceCost || "0");
      setFreeShipping(listingInfo?.shippingDetails?.freeShipping || false);
    }
  }, [listingInfo]);

  const handleSave = (updateType) => {
    dispatch(updateListing({
      listingId,
      updatedListing: {
        ebayTitle,
        startPrice,
        startPriceFixed,
        quantity,
        shippingCost,
        freeShipping,
        updateType
      }
    }));

    dispatch(getListing(listingId));
    setSaveDialogOpen(false);
  };

  const handleDelete = () => {
    dispatch(deleteListing([listingId]));
    navigate(-1);
  };

  return (
    <Box m="1.5rem 2.5rem" pb="2rem">
      <Header
        title={
          <span>
            Listing (
            <a
              href={`https://www.ebay.com/itm/${listingInfo.ebayItemId}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: theme.palette.secondary[200] }}
            >
              {listingInfo.ebayItemId}
            </a>
            )
          </span>
        }
        subtitle="Edit And Manage Your Listing"
      />
      <Box
        mt="3rem"
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "75%",
          gap: "2rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <SubHeader title="General Info" />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "60%",
              borderRadius: "10px",
              bgcolor: theme.palette.background.alt,
              padding: "2rem",
              gap: "2rem"
            }}
          >
            <Box>
              <Typography variant="h6" p="3px" mb="10px">ebay Title</Typography>
              <TextField
                id="outlined-read-only-input"
                label={`ebay Title ${ebayTitle.length}/80`}
                value={ebayTitle}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue.length <= 80) {
                    setEbayTitle(inputValue);
                  }
                }}
                fullWidth
                color="secondary"
              />
            </Box>
            <Box>
              <Typography variant="h6" p="3px" mb="10px">Supplier Description</Typography>
              <TextField
                id="outlined-multiline-static"
                label="Supplier Description"
                multiline
                value={listingInfo?.inventoryInfo?.description || ""}
                rows={4}
                fullWidth
                color="secondary"
                placeholder="Supplier description..."
              />
            </Box>
            <Box>
              <Typography variant="h6" p="3px" mb="5px">Synced ?</Typography>
              <TextField
                id="outlined-read-only-input"
                label="Synced"
                value={listingInfo?.synced || ""}
                color="secondary"
                sx={{
                  width: "45%"
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between"
              }}
            >
              <Box
                sx={{
                  width: "45%"
                }}
              >
                <TextField
                  id="outlined-read-only-input"
                  label="SKU"
                  value={listingInfo?.sku || ""}
                  color="secondary"
                  fullWidth
                />
              </Box>
            </Box>
          </Box>
        </Box>
        {
          listingInfo.errorLogs && listingInfo.errorLogs.length > 0 &&
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <SubHeader title="Error Logs" />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "60%",
                borderRadius: "10px",
                bgcolor: theme.palette.background.alt,
                padding: "2rem",
                gap: "2rem"
              }}
            >
              {listingInfo?.errorLogs.map((errorLog, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between"
                  }}
                >
                  <Box
                    sx={{
                      width: "15%"
                    }}
                  >
                    <TextField
                      id={`outlined-read-only-input-${index}`}
                      label="Error Id"
                      value={errorLog.id}
                      InputProps={{
                        readOnly: true,
                      }}
                      color="secondary"
                      fullWidth
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "75%"
                    }}
                  >
                    <TextField
                      id={`outlined-read-only-input-message-${index}`}
                      label="Error Message"
                      value={errorLog.message}
                      InputProps={{
                        readOnly: true,
                      }}
                      color="secondary"
                      fullWidth
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        }
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <SubHeader title="Product Pricing" />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "60%",
              borderRadius: "10px",
              bgcolor: theme.palette.background.alt,
              padding: "2rem",
              gap: "2rem"
            }}
          >
            <Box>
              <TextField
                id="outlined-read-only-input"
                label="Previous Price"
                value={listingInfo.previousPrice || ""}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
                color="secondary"
              />
            </Box>
            <Box>
              <TextField
                id="outlined-textarea"
                label="Start Price"
                value={startPrice}
                onChange={(e) => setStartPrice(e.target.value)}
                color="secondary"
                sx={{
                  width: "75%",
                }}
              />
              <FormControl
                component="fieldset"
                sx={{
                  width: "25%"
                }}
              >
                <FormControlLabel
                  control={<Switch
                    color="secondary"
                    checked={startPriceFixed}
                    onChange={() => setStartPriceFixed(!startPriceFixed)}
                  />}
                  label="Fixed Price"
                  labelPlacement="top"
                />
              </FormControl>
            </Box>
            <Divider middle />
            <Box>
              <TextField
                id="outlined-read-only-input"
                label="Shipping Cost"
                value={listingInfo?.inventoryInfo?.shippingCost || ""}
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  width: "45%"
                }}
                color="secondary"
              />
            </Box>
            {listingInfo?.supplierPrice &&
              <Box>
                <Typography variant="h6" p="3px" mb="5px">Supplier Price</Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                  }}
                >
                  <TextField
                    label="Supplier Price"
                    value={listingInfo?.supplierPrice}
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{
                      width: "45%",
                    }}
                    color="secondary"
                  />
                </Box>
              </Box>
            }
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <SubHeader title="Product Quantity" />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "60%",
              borderRadius: "10px",
              bgcolor: theme.palette.background.alt,
              padding: "2rem",
              gap: "2rem"
            }}
          >
            <Box>
              <TextField
                id="outlined-read-only-input"
                label="Previous Quantity"
                value={listingInfo.previousQuantity || 0}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
                color="secondary"
              />
            </Box>
            <Divider middle />
            <Box>
              <TextField
                id="outlined-textarea"
                label="Available Quantity"
                value={quantity}
                InputProps={{
                  readOnly: true,
                }}
                color="secondary"
                fullWidth
              />
            </Box>
            <Box>
              <TextField
                id="outlined-read-only-input"
                label="Sold Quantity"
                value={listingInfo.soldQuantity || 0}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
                color="secondary"
              />
            </Box>
            <Box>
              {listingInfo?.supplierQuantity &&
                <TextField
                  id="outlined-read-only-input"
                  label="Supplier Quantity"
                  value={listingInfo.supplierQuantity || 0}
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                  color="secondary"
                />
              }
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <SubHeader title="ebay Shipping" />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "60%",
              borderRadius: "10px",
              bgcolor: theme.palette.background.alt,
              padding: "2rem",
              gap: "2rem"
            }}
          >
            <Box>
              <TextField
                id="outlined-read-only-input"
                label="Shipping Service"
                value={listingInfo?.shippingDetails?.service || ""}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
                color="secondary"
              />
            </Box>
            <Box>
              <TextField
                id="outlined-textarea"
                label="Shipping Cost"
                value={shippingCost}
                onChange={(e) => setShippingCost(e.target.value)}
                color="secondary"
                sx={{
                  width: "75%",
                }}
              />
              <FormControl
                component="fieldset"
                sx={{
                  width: "25%"
                }}
              >
                <FormControlLabel
                  control={<Switch
                    color="secondary"
                    checked={freeShipping}
                    onChange={() => setStartPriceFixed(!freeShipping)}
                  />}
                  label="Free Shipping"
                  labelPlacement="top"
                />
              </FormControl>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "40px"
          }}
        >
          <LoadingButton
            color="secondary"
            variant="contained"
            sx={{
              width: "20%",
              padding: "10px",
              alignSelf: "flex-end"
            }}
            onClick={() => setDeleteDialogOpen(true)}
            loading={deleteStatus === "pending"}
          >Delete</LoadingButton>
          <LoadingButton
            color="secondary"
            variant="contained"
            sx={{
              width: "20%",
              padding: "10px",
              alignSelf: "flex-end"
            }}
            onClick={() => setSaveDialogOpen(true)}
            loading={updateStatus === 'pending'}
          >Save</LoadingButton>
        </Box>
      </Box>
      <DialogBox
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={handleDelete}
        titleText="Delete Confirmation"
        messageText="Are you sure you want to delete this listing?"
        confirmText="Confirm Delete"
      />
      <DialogBox
        open={saveDialogOpen}
        onClose={() => setSaveDialogOpen(false)}
        onConfirm={() => handleSave('revise')}
        titleText="Save Confirmation"
        messageText="Are you sure you want to save this change?"
        confirmText="Revise Item"
      />
    </Box>
  );
}

export default StoreEdit;
