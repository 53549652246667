import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createUserApi, getUsersApi } from "./staffApi";
import { notifyMsg } from "state/api";

export const createUser = createAsyncThunk("staff/createUser", async (credentials) => {
  const response = await createUserApi(credentials);
  notifyMsg(response);
  return response;
});

export const getUsers = createAsyncThunk("staff/getUsers", async({ page, pageSize }) => {
  const response = await getUsersApi(page, pageSize);
  return response;
});

const initialState = {
  createUserStatus: "",
  totalUsers: 0,
  users: []
};

const staffSlice = createSlice({
  name: "staffSlice",
  initialState,
  reducers: {
    clearStatus: (state) => {
      state.createUserStatus = "";
    }
  },
  extraReducers: (builder) => {
    builder
      // createUser
      .addCase(createUser.pending, (state) => {
        state.createUserStatus = "pending";
      })
      .addCase(createUser.fulfilled, (state, { payload }) => {
        state.createUserStatus = "fulfilled";
      })
      .addCase(createUser.rejected, (state) => {
        state.createUserStatus = "rejected";
      })
      // getUsers
      .addCase(getUsers.fulfilled, (state, { payload }) => {
        if (payload) {
          state.users = payload.data.users;
          state.totalUsers = payload.data.totalUsers;
        }
      });
  },
});

export const { clearStatus } = staffSlice.actions;
export default staffSlice.reducer;
