import api from "state/api";
import { getConfigWithBearerToken } from "state/api";

export const getSuppliersApi = async (type, page, pageSize) => {
  try {
    const response = await api.get(`suppliers/config?type=${type}&page=${page}&pageSize=${pageSize}`, getConfigWithBearerToken());
    return response.data;
  } catch (error) {
    console.error("Error getSuppliersApi:", error.message);
  }
};

export const getScrapedInventoryApi = async (page, pageSize, search, filters) => {
  try {
    const response = await api.post(
      `suppliers/inventory?searchTerm=${search}&page=${page}&pageSize=${pageSize}`,
      { filters },
      getConfigWithBearerToken()
    );
    return response.data;
  } catch (error) {
    console.error("Error getScrapedInventoryApi:", error.message);
  }
};

export const getScrapedInventoryXlsxApi = async (search, filters, selectedItems) => {
  try {
    const response = await api.post(
      `suppliers/inventory/xlsx?searchTerm=${search}`,
      { filters, selectedItems },
      {
        responseType: 'blob',
        ...getConfigWithBearerToken()
      }
    );

    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "scraped-items.xlsx";
    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error getScrapedInventoryXlsxApi:", error.message);
  }
};

export const getScrapedItemApi = async (scrapedItemId) => {
  try {
    const response = await api.get(
      `suppliers/inventory/${scrapedItemId}`,
      getConfigWithBearerToken()
    );
    return response.data;
  } catch (error) {
    console.error("Error getScrapedItemApi:", error.message);
  }
};

export const deleteScrapedInventoryApi = async (type, data) => {
  try {
    const response = await api.post(
      `suppliers/inventory/delete/${type}`,
      { data },
      getConfigWithBearerToken()
    );
    return response.data;
  } catch (error) {
    console.error("Error deleteScrapedInventoryApi:", error.message);
  }
};

export const getSupplierInfoApi = async (supplierId) => {
  try {
    const response = await api.get(`suppliers/config/${supplierId}`, getConfigWithBearerToken());
    return response.data;
  } catch (error) {
    console.error("Error getSupplierInfoApi:", error.message);
  }
};

export const getSupplierScraperInfoApi = async (supplierId) => {
  try {
    const response = await api.get(`suppliers/config/scraper/${supplierId}`, getConfigWithBearerToken());
    return response.data;
  } catch (error) {
    console.error("Error getSupplierScraperInfoApi:", error.message);
  }
};

export const updateSupplierInfoApi = async (supplierId, skuPrefix) => {
  try {
    const response = await api.put(
      `suppliers/config/${supplierId}`,
      { skuPrefix },
      getConfigWithBearerToken()
    );
    return response.data;
  } catch (error) {
    console.error("Error updateSupplierInfoApi:", error.message);
  }
};

export const updateSupplierScraperInfoApi = async (supplierId, supplierScraperConfig, supplierSiteCredentials) => {
  try {
    const response = await api.put(
      `suppliers/config/scraper/${supplierId}`,
      { supplierScraperConfig, supplierSiteCredentials },
      getConfigWithBearerToken()
    );
    return response.data;
  } catch (error) {
    console.error("Error updateSupplierScraperInfoApi:", error.message);
  }
};

export const fixedShippingFormatApi = async () => {
  try {
    const response = await api.get(`suppliers/shipping/fixed-shipping-format`, {
      responseType: 'blob',
      ...getConfigWithBearerToken()
    });

    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "fixed-shipping-format.xlsx";
    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error fixedShippingFormatApi:', error.message);
  }
};

export const fixedShippingUploadApi = async (supplierId, file) => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const response = await api.post(`suppliers/shipping/fixed-shipping/${supplierId}`, formData, {
      headers: {
        ...getConfigWithBearerToken().headers,
        'Content-Type': 'multipart/form-data'
      }
    });

    return response.data;
  } catch (error) {
    console.error("Error fixedShippingUploadApi:", error.message);
  }
};

export const updateShippingWeightsApi = async (supplierId, shippingWeights) => {
  try {
    const response = await api.put(
      `suppliers/shipping/shipping-weights/${supplierId}`,
      { shippingWeights },
      getConfigWithBearerToken()
    );

    return response.data;
  } catch (error) {
    console.error("Error updateShippingWeightsApi:", error.message);
  }
};