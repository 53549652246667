import React, { useState, useEffect } from "react";

import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import Header from "components/Header";

import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "features/staff/staffSlice";

const Users = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { users, totalUsers } = useSelector((state) => state.staff);

  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);

  useEffect(() => {
    dispatch(getUsers({ page: page + 1, pageSize }));
    setLoading(false);
  }, [page, pageSize]);

  const columns = [
    {
      field: "profilePicture",
      headerName: "Profile Picture",
      flex: 1,
      renderCell: (params) => (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
          width="100%"
        >
          <img
            alt="profile"
            src={params.row.profileImage ?
              `${process.env.REACT_APP_BASE_PREFIX}/profileImages/${params.row.profileImage}` :
              (theme.palette.mode === "dark" ?
                process.env.REACT_APP_DEFAULT_PFP_LIGHT :
                process.env.REACT_APP_DEFAULT_PFP_DARK)
            }
            style={{
              height: "45px",
              width: "45px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
        </Box>
      ),
    },
    { field: "randomId", headerName: "ID", flex: 2 },
    { field: "name", headerName: "Name", flex: 2 },
    { field: "email", headerName: "Email", flex: 2 },
    { field: "createdAt", headerName: "Created At", flex: 2 },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Users" subtitle="Manage Your Users" />
      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.primary[500],
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.primary[500],
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          sx={{
            marginTop: "15px",
            "& .MuiDataGrid-cell": {
              borderBottom: `1px solid ${theme.palette.secondary[200]}`
            },
          }}
          loading={loading}
          rows={users || []}
          rowCount={totalUsers}
          columns={columns}
          rowsPerPageOptions={[20, 50, 100]}
          pagination
          page={page}
          pageSize={pageSize}
          paginationMode="server"
          sortingMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        />
      </Box>
    </Box>
  );
};

export default Users;