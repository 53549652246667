import React, { useState, useEffect } from "react";

import {
  Box,
  TextField,
  Typography,
  Divider,
  Switch,
  FormControl,
  Fab,
  useTheme
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

import {
  AddOutlined,
  Delete
} from "@mui/icons-material";

import moment from "moment";

import Header from "components/Header";
import SubHeader from "components/SubHeader";
import DialogBox from "components/DialogBox";

import { useDispatch, useSelector } from "react-redux";
import {
  getAutomations,
  updateAutomations
} from "features/automations/automationsSlice";

const Automations = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    automations,
    updateStatus
  } = useSelector((state) => state.automations);

  const [scraperSchedules, setScraperSchedules] = useState([{
    schedule: null
  }]);

  const [saveDialogOpen, setSaveDialogOpen] = useState(false);

  useEffect(() => {
    dispatch(getAutomations());
  }, []);

  useEffect(() => {
    if (automations?.scrapers?.schedules.length > 0) {
      setScraperSchedules([
        ...automations.scrapers.schedules.map(({ schedule }) => ({ schedule: moment(schedule)}))
      ]);
    }

  }, [automations]);

  const handleSave = () => {
    dispatch(updateAutomations({ scrapers: { schedules: scraperSchedules } }));
    setSaveDialogOpen(false);
  };

  const handleScrapersSchedulesChange = (value, index) => {
    setScraperSchedules(prev => {
      return prev.map((schedule, Innerndex) => Innerndex === index ? { schedule: value } : schedule);
    });
  };

  const handleAddScrapersSchedule = () => {
    setScraperSchedules(prev => {
      return [
        ...prev,
        { schedule: null }
      ]
    });
  };

  const handleRemoveScrapersSchedule = (index) => {
    setScraperSchedules(prev => {
      return prev.filter((_, Innerndex) => Innerndex !== index);
    });
  };

  return (
    <Box m="1.5rem 2.5rem" pb="2rem">
      <Header title="Automation Jobs" subtitle="Manage Your Automation Jobs" />
      <Box
        mt="3rem"
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "75%",
          gap: "2rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <SubHeader title="Scrapers Automation Job" />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "60%",
              borderRadius: "10px",
              bgcolor: theme.palette.background.alt,
              padding: "2rem",
              gap: "2rem"
            }}
          >
            <Typography fontWeight="bold" variant="h5" p="3px">Scrapers Schedule Control</Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: "2rem"
              }}
            >
              {
                scraperSchedules.map(({ schedule }, index) => (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%"
                    }}
                  >
                    <DesktopTimePicker
                      label={`Schedule ${index + 1}`}
                      slotProps={{ textField: { color: "secondary" } }}
                      value={schedule}
                      onChange={(newValue) => handleScrapersSchedulesChange(newValue, index)}
                      sx={{
                        width: "45%"
                      }}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                    />

                    <Fab
                      size="small"
                      color="secondary"
                      aria-label="remove"
                      onClick={() => handleRemoveScrapersSchedule(index)}
                    >
                      <Delete />
                    </Fab>
                  </Box>
                ))
              }
              <Fab
                size="small"
                color="secondary"
                aria-label="add"
                onClick={handleAddScrapersSchedule}
              >
                <AddOutlined />
              </Fab>
            </Box>
          </Box>
        </Box>
        <LoadingButton
          color="secondary"
          variant="contained"
          sx={{
            width: "20%",
            padding: "10px",
            alignSelf: "flex-end"
          }}
          onClick={() => setSaveDialogOpen(true)}
        // loading={updateStatus === "pending"}
        >
          Save
        </LoadingButton>
        <DialogBox
          open={saveDialogOpen}
          onClose={() => setSaveDialogOpen(false)}
          onConfirm={handleSave}
          titleText="Save Confirmation"
          messageText="Are you sure you want to save these changes?"
          confirmText="Save"
        />
      </Box>
    </Box>
  );
}

export default Automations;